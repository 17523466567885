

// only num
export const numHandleKeyPress = (event) => {
  if (
    !/[\d]/.test(event.key) && // Only allow digits
    event.key !== "Backspace" && // Backspace
    event.key !== "Delete" && // Delete
    (event.key !== "+" || event.target.value.includes("+")) // Plus symbol
  ) {
    event.preventDefault();
  }
};
// sign up validation
export const supValidate = (value) =>{
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const error ={};
  if(!value.first_name){
  error.first_name = "Please enter the Firstname"
  }
  if(!value.last_name){
    error.last_name = "Please enter the Lastname"
  }
  if(!value.username){
    error.username = "Please enter the UserName"
  }
  if(!value.organization){
    error.organization = "Please enter the Organization"
  }
  if(!value.email){
    error.email = "Please enter the Email Address"
  }
  else if(!value.email.match(emailRegex)){
   
    error.email = "Please enter valid Email Address"
  }
  if(!value.confirm_email){
        error.confirm_email="Please enter the confirm Email address"
  }
  else if(value.email !== value.confirm_email){
    error.confirm_email="Email Address does not match"
  }


  if(!value.password){
        error.password="Please enter the Password"
  }
  if(!value.confirm_password){
    error.confirm_password="Please enter the Confirm Password"
}
  else if(value.password !== value.confirm_password){
    error.confirm_password="Password does not match"
  }


if(value.privacy_policy !== 1){
  error.privacy_policy = " Please accept the privacy policy";
}
if(value.terms_and_conditions !== 1){
  error.terms_and_conditions = " Please accept the terms and condition";
}
  return error;
}
// sign in validation
export const sinValidate = (value) =>{
  const error ={};
  if(!value.username_email){
  error.user = "Please enter the Email"
  }
  return error;
}
//basic validation
export const validateDetail = (values) => {
  const error = {};
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  if (!values.name) {
    error.name = "Please enter your name";
  }
  if (!values.telephone) {
    error.telephone = "Please enter your contact number";
  }
  else if (values.telephone.length < 10) {
    error.telephone = " Minimum 10 numbers required";
  }
  if (!values.message) {
    error.message = "Please enter minimum 50 characters";
  } else if (values.message.length < 50) {
    error.message = " Minimum 50 characters required";
  }
  if (!values.reference) {
    error.reference = "Please enter your reference";
  }
  if(values.agreement !== 1){
    error.agreement = " Please accept the terms and conditions";
  }
  if(values.consent !== 1){
    error.consent = " Please accept the terms and conditions";
  }
  if (!values.receiver) {
    error.receiver = "Please enter your email address";
  }
  else if (!values.receiver.match(emailRegex)) {
    error.receiver = " Please enter a valid email";
  }
  return error;
};

//basic validation
export const validateDonorFirstTabDetail = (values,root) => {
 
  const error = {};
  if (!values.submitted_date) {
    error.submitted_date = "Please enter your submitted date";
  }
  if (!values.address) {
    error.address = "Please enter the location";
  }
  if (!values.quantity) {
    error.quantity = "Please enter the quantity available";
  }
  if (!values.available_from) {
    error.available_from = "Please select the available from date";
  }
  if (!values.available_to) {
    error.available_to = "Please select the available to date";
  }
  if (!values.material_type) {
    error.material_type = "Please select the material type";
  }
  if(root !== "donor"){
    if (!values.specified_end_user) {
      error.specified_end_user = "Please enter the specified end use";
    }
  }
 
  return error;
};

export const validateDonorSecondTabDetail = (values,root) => {
  const error = {};
  if( root !== "donor"){
    if (!values.map_latitude) {
      error.map_latitude = "Please enter the latitude";
    }
    if (!values.map_longitude) {
      error.map_longitude = " Please enter the longitude";
    }
  }

  return error;
};
export const validateDonorThirdTabDetail = (values) => {
  const error = {};
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  if (!values.name) {
    error.name = "Please enter your name";
  }
  if (!values.email) {
    error.email = " Please enter your email";
  }
  else if (!values.email.match(emailRegex)) {
    error.email = " Please enter a valid email";
  }
  if (!values.company) {
    error.company = " Please enter the company ";
  }
  if(values.agreement !== 1){
    error.agreement = " Please accept the terms and conditions";
  }
  if(values.consent !== 1){
    error.consent = " Please accept the terms and conditions";
  }
  return error;
};

export const validateSoilTabDetail = (values) => {
  const error = {};
  if (!values.submitted_date) {
    error.submitted_date = "Please enter the submitted date";
  }
  if (!values.address) {
    error.address = "Please enter the location";
  }
  if (!values.yearly_intake) {
    error.yearly_intake = "Please enter the yearly intake";
  }
  if (!values.treatable_materails) {
    error.treatable_materails = "Please enter the treatable materials";
  }
  if (!values.timescale) {
    error.timescale = "Please select the time scale";
  }
  return error;
};
