import "./style.scss";
import React, { useEffect, useRef, useState } from "react";

const MultiselDropdown = ({ options,formMultiSelectedOption,setMultiFormSelectedOption, label, error }) => {
  const [isOpen, setIsOpen] = useState(false);
 
 
  const handleOptionSelect = (option) => {
    setMultiFormSelectedOption(option);
  };

  let dropRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!dropRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  return (
    <>
         <div className="selectLabel">{label}</div>
         <div className="mls_dropdown_container" ref={dropRef}>
      <div
        className="mls_dropdown_header"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <ul className="mls_header_wrapper">
          {formMultiSelectedOption.map((selected, i) => {
            return (
              <li className="mls_sel_data" key={i}>
                {selected.label}
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                     setIsOpen(true)
                    setMultiFormSelectedOption(
                      formMultiSelectedOption.filter(
                        (item) => item.label !== selected.label
                      )
                    );
                  }}
                >
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 12 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      y1="-0.25"
                      x2="14.142"
                      y2="-0.25"
                      transform="matrix(0.707114 -0.7071 0.707114 0.7071 1 11)"
                      stroke="#B5BF00"
                      strokeWidth="0.5"
                    />
                    <line
                      y1="-0.25"
                      x2="14.142"
                      y2="-0.25"
                      transform="matrix(0.707114 0.7071 -0.707114 0.7071 1 1)"
                      stroke="#B5BF00"
                      strokeWidth="0.5"
                    />
                  </svg>
                </span>
              </li>
            );
          })}
        </ul>
        <span
          style={
            isOpen ? { transform: " translateY(-50%) rotate(180deg)  " } : {}
          }
        >
          <svg
            width="18"
            height="9"
            viewBox="0 0 18 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 9L0.339746 5.36442e-07L17.6603 5.36442e-07L9 9Z"
              fill="#888888"
            />
          </svg>
        </span>
      </div>
      {
        <ul
          className={`${
            isOpen ? "mls_dropdown_options open" : "mls_dropdown_options"
          }`}
        >
          {options.map((option) => (
            <li
              key={option.value}
              onClick={() => {
                if (
                  formMultiSelectedOption.some(
                    (item) => item.value === option.value
                  )
                ) {
                  setMultiFormSelectedOption(
                    formMultiSelectedOption.filter(
                      (item) => item.value !== option.value
                    )
                  );
                } else {
                  setMultiFormSelectedOption([
                    ...formMultiSelectedOption,
                    option,
                  ]);
                }
              }}
            >
              <span
                className={
                  formMultiSelectedOption.some(
                    (item) => item.value === option.value
                  )
                    ? "mls_check checked"
                    : "mls_check"
                }
              ></span>{" "}
              <span>{option.label}</span>
            </li>
          ))}
        </ul>
      }
    </div>
    <div className="errorText">{error}</div>
    </>
  
  );
};

export default MultiselDropdown;
