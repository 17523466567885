import "./style.scss";
import React, { useEffect, useRef, useState } from "react";

const MtDropdown = ({ options, setopen, setFilterOptionData ,open ,filterOptionData}) => {

  // const handleOptionSelect = (option) => {
  //   setSelectedOption(option);
  //   setIsOpen(false);
  // };
 
  return (
    <>
      
      <div
        className="mt_dropdown_container"
        // ref={menuRef}
      >
        {
          <ul className={`${open ? "mt_dropdown_options open" : "mt_dropdown_options"}`}>
            {options.map((option) => (
              <li key={option.value} onClick={() =>{ 
                if (filterOptionData.some(item => item.value === option.value)) {
                  setFilterOptionData(filterOptionData.filter(item => item.value !== option.value));
                } else {
                 
                  setFilterOptionData([...filterOptionData, option]);
                }
            }}>
              
                    <span className={filterOptionData.some(item => item.value === option.value) ? "mt_check checked" : "mt_check"} 
                    ></span>    <span>{option.label}</span> 
              </li>
            ))}
          </ul>
        }
      </div>
    </>
  );
};

export default MtDropdown;
