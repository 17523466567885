import * as React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './style.scss';

export default function DatePickerValue(props) {
    const { onDatePicker, error, label, value,minDate } = props;
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className='datePicker'>
                <div className="label">{label}</div>
                <DatePicker
                    error={error}
                    value={dayjs(value)}

                    onChange={(newValue) => {
            
                        onDatePicker(newValue)
                       }
                      }
                      format='DD/MM/YYYY'
                       minDate={minDate ? dayjs(minDate) : undefined}
                     
                      
                />
                <div className="errorText">{props.error}</div>
            </div>
        </LocalizationProvider>
    );
}