import "./style.scss";
import React, { useEffect } from "react";
import axios from "axios";
import TextInput from "../../components/textInput";
import { useState } from "react";
import Button from "../../components/button";
import { sinValidate } from "../../utils/validation";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useContext } from "react";
import { SiteContext } from "../../utils/context";
import Map from "../../components/map";

const sites = [
  {
    color: "#37A6D7",
    value: "Donor Sites",
    key: "donor",
  },
  {
    color: "#71AF26",
    value: "Receiver Sites",
    key: "receiver",
  },
  {
    color: "#583768",
    value: "Soil Treatment Sites",
    key: "soil_treatment",
  },
];

const SignIn = () => {
 
  const navigate = useNavigate();
  const prevloginData = useRef();
  
  const{isAdmin,setIsAdmin}=useContext(SiteContext);
  // user login crendials
  const [login, setLoginData] = useState({
    username_email: ""
  });
  // error state
  const [loginError, setLoginError] = useState("");
  const submitHandler = (e) => {
    e.preventDefault();
    setLoginError(sinValidate(login));
  };
  const [mapData, setMapData] = useState({});
  const [activeMap, setActiveMap] = useState(
    "noType"
    //   {
    //   color: "#37A6D7",
    //   value: "Donor Sites",
    //   key: "donor",
    // }
  );
    const getApiCall = (el) => {
    axios
      .get(`/api/v1/map?type=${el}`
      // , {
      //   headers: {
      //     Authorization:
      //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6IlZpamF5IiwiZW1haWwiOiJ2aWpheW90aGVyczEzQGdtYWlsLmNvbSIsImlhdCI6MTY3OTA1NzQ5NiwiZXhwIjoxNzEwNTkzNDk2fQ.bGqfuMdbYNDgD7oEflYPWvLrdn1b2AVJ5DcnosDdkM0",
      //   },
      // }
      )
      .then((response) => {
        setMapData({});
        setTimeout(() => {
          setMapData(response?.data?.data);
        }, 100);
      });
  };
  
  useEffect(() => {
    // map data
    axios
      .get(`${ process.env. REACT_APP_API_URL}map`,
      //  {
      //   headers: {
      //     Authorization:
      //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6IlZpamF5IiwiZW1haWwiOiJ2aWpheW90aGVyczEzQGdtYWlsLmNvbSIsImlhdCI6MTY3OTA1NzQ5NiwiZXhwIjoxNzEwNTkzNDk2fQ.bGqfuMdbYNDgD7oEflYPWvLrdn1b2AVJ5DcnosDdkM0",
      //   },
      // }
      )
      .then((response) => setMapData(response?.data?.data));
  }, []);

  useEffect(() => {
    if (
      Object.values(login).every((value) => !!value) && Object.keys(loginError).length === 0
    ) {
      if (JSON.stringify(login) !== JSON.stringify(prevloginData.current)) {
        axios
        .post(`${process.env.REACT_APP_API_URL}login`, login)
        .then((res) => {
            setLoginError("");
      
            sessionStorage.setItem("token",JSON.stringify(res.data.data.token));
            sessionStorage.setItem("userid",JSON.stringify(res.data.data.id));
	    sessionStorage.setItem("company",JSON.stringify(res.data.data.company));
	    let fullName = JSON.stringify(res.data.data.firstname) + " " + JSON.stringify(res.data.data.lastname);
	    sessionStorage.setItem("fullName",fullName);
	    sessionStorage.setItem("email",login.username_email);
            if(res.data.data.username === "rom"){
              setIsAdmin(true)
            }
            else{
              setIsAdmin(false)
            }
            //navigate("/register-of-materials");
	  if (sessionStorage.getItem("siteUrl") === "Donor Site") {
		navigate("/register-of-materials/donor?rootName=donor");
	  } else if (sessionStorage.getItem("siteUrl") === "Receiver Site") {
		navigate("/register-of-materials/donor?rootName=receiver");
	  } else if (sessionStorage.getItem("siteUrl") === "Soil Treatment Facility") {
		navigate("/register-of-materials/donor?rootName=soil_treatment");
	  }
        })
        .catch((err) => {
          setLoginError({user:err.response.data.message});
        });
         // Store the current data as the previous data
        prevloginData.current=login;
      }
  
    }
  }, [loginError]);

  const onClear = () => {
    setActiveMap("noType");
    axios
      .get(`${ process.env. REACT_APP_API_URL}map`,
      // {
      //   headers: {
      //     Authorization:
      //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6IlZpamF5IiwiZW1haWwiOiJ2aWpheW90aGVyczEzQGdtYWlsLmNvbSIsImlhdCI6MTY3OTA1NzQ5NiwiZXhwIjoxNzEwNTkzNDk2fQ.bGqfuMdbYNDgD7oEflYPWvLrdn1b2AVJ5DcnosDdkM0",
      //   },
      // }
      )
      .then((response) => {
        setMapData({});
        setTimeout(() => {
          setMapData(response?.data?.data);
        }, 100);
      });
  };
   const onFilter = (elem) => {
    if (elem?.key) {
      setActiveMap(elem);
      // setTimeout(() => {
      getApiCall(elem?.key);
      // }, 2000);
    }
  };
  const mOptions = [
    { value: "Donor Site", label: "Donor Site", type: "donor" },
    { value: "Receiver Site", label: "Receiver Site ", type: "receiver" },
    {
      value: "Soil Treatment Facility",
      label: "Soil Treatment Facility",
      type: "soil_treatment",
    },
  ];

  return (
    <main className="cl_container">
      <section className="home_sec_location cl_container">
        <h3>Locations</h3>
        <div className="map_content_wrapper">
          <Map
            activeMap={activeMap}
            data={Object.keys(mapData).length > 0 ? mapData : []}
            sites={sites}
            onClear={onClear}
            onFilter={onFilter}
          />

        </div>
      </section>

      <section className="cl_signin">
        <div className="cl_signin_wrapper">
          <h3>Verify Login: Enter Email</h3>

          <form
            className="cl_signin_form_wrapper"
            onSubmit={(e) => {
              submitHandler(e);
            }}
          >
            <div className="cl_si_formblk">
              <div className="cl_signin_inpblk">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 3.75C11.0054 3.75 10.0516 4.14509 9.34835 4.84835C8.64509 5.55161 8.25 6.50544 8.25 7.5C8.25 8.49456 8.64509 9.44839 9.34835 10.1517C10.0516 10.8549 11.0054 11.25 12 11.25C12.9946 11.25 13.9484 10.8549 14.6517 10.1517C15.3549 9.44839 15.75 8.49456 15.75 7.5C15.75 6.50544 15.3549 5.55161 14.6517 4.84835C13.9484 4.14509 12.9946 3.75 12 3.75ZM8 13.25C7.00544 13.25 6.05161 13.6451 5.34835 14.3483C4.64509 15.0516 4.25 16.0054 4.25 17V18.188C4.25 18.942 4.796 19.584 5.54 19.705C9.818 20.404 14.182 20.404 18.46 19.705C18.8201 19.6464 19.1476 19.4616 19.3839 19.1837C19.6202 18.9057 19.75 18.5528 19.75 18.188V17C19.75 16.0054 19.3549 15.0516 18.6517 14.3483C17.9484 13.6451 16.9946 13.25 16 13.25H15.66C15.475 13.25 15.291 13.28 15.116 13.336L14.25 13.619C12.788 14.0962 11.212 14.0962 9.75 13.619L8.884 13.336C8.70865 13.2789 8.5254 13.2499 8.341 13.25H8Z"
                      fill="#B5BF00"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  name="username"
                  value={login.username_email}
                  onChange={(e) => {
                    setLoginData({
                      ...login,
                      username_email: e.target.value,
                    });
                  }}
                  placeholder="Email"
                  autoComplete="off"
                />
              </div>
              <p className="cl_si_error">{loginError.user}</p>
            </div>
            <Button
              variant="arrow_btn_primary"
              textContent="Verify"
              typeSubmit="submit"
            />
          </form>
        </div>
      </section>
    </main>
  );
};

export default SignIn;
