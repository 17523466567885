import "./style.scss";
import React, { useEffect, useState } from "react";

const TableDropdown = ({ options,tdClickHandler,setTableType}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  
  useEffect(()=>{
    setTableType(selectedOption.type)
  },[selectedOption])
  return (
    <div
      className="td_dropdown_container"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="td_dropdown_header">
        {selectedOption.label }
        <span style={isOpen ? { transform: 'rotate(180deg)' } : {}}>
          <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 -0.000488281L17.6603 8.99951H0.339745L9 -0.000488281Z" fill="#B5BF00"/>
</svg>

          </span>
      </div>
      {
        <ul className={`${ isOpen ? "td_dropdown_options open" : "td_dropdown_options"}`}>
          {options.map((option,i) => (
          
         
            <li key={i} style={{color:selectedOption.label === option.label && "#B5BF00" }}  onClick={() => {handleOptionSelect(option);tdClickHandler(option.type)}}>
              {option.label}
            </li>
            
          ))}
        </ul>
      }
    </div>
  );
};

export default TableDropdown;
