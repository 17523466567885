import "./style.scss";

// paginate
import ReactPaginate from "react-paginate";
import React, { useContext, useEffect, useRef, useState } from "react";
import FilterIcon from "../../assets/images/filtericon";
// component
import DropDown from "../dropdown";
import MtDropdown from "../filtermaterialtype";
import dayjs from "dayjs";
import { SiteContext } from "../../utils/context";
import { dateFormatter } from "../../utils";
import { useNavigate } from "react-router-dom";
import axios from "axios";
function apiMaterialList(data){
  const materialList = data.material_type.split(',');
  const result = materialList.map(material => {
    return {
      label: material,
      value: material.toLowerCase().replace(' ', '_')
    };
  });
  return result;
}

const FilterTable = (props) => {
  const { userToken, setUserToken,formMultiSelectedOption,setMultiFormSelectedOption,soilData,setSoilValues,siteData,setSiteValues,siteMapData, setSiteMapValues,siteContactData, setSiteContactValues,isAdmin=false,setEditId,setIsEdit} = useContext(SiteContext);
  const {
    data_Flt,
    onHandleChange,
    setData,
    onSortKey,
    eventSel,
    setEvenSel,
    valueData,
    tabletype,
    filterOptionData,
    setFilterOptionData,
    itemOffset,
    setItemOffset,
    itemsPerPage,
    setItemsPerPage,
    tableStartIndex,
    setTableStartIndex,
    total_data,
    keyData,
  } = props;
  const navigate = useNavigate();
  const today = dayjs(); // Get today's date
   const USERID = sessionStorage.getItem("userid");

   const [isEditHeadVisible,setIsEditHeadVisible]=useState(false);
  const fl_table = useRef(null);
  //maintain current items in current page
 
  //  filter
  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(count + 1);
    oneditThHeader();
  }, [data_Flt]);

  const [open, setopen] = useState(false);

  const filterOption = [
    { value: "top_soil", label: "Top Soil" },
    { value: "sub_soil", label: "Sub Soil" },
    { value: "sand", label: "Sand" },
    { value: "gravel", label: "Gravel" },
    { value: "clay", label: "Clay" },
    { value: "aggregate", label: "Aggregate" },
  ];
  const options = [
    { value: "5", label: 5 },
    { value: "10", label: 10 },
    { value: "20", label: 20 },
    { value: "50", label: 50 },
  ];
  const [trackListNum, SetTrackListNum] = useState(0);
  //items per page
  // //per page item start num and end num
  // const [itemNum, setItemNum] = useState({ start: null, end: null });
  // //    pagination start
  // const [endNumPage, setEndNumPage] = useState(1);
  // const indexOfLastItem = itemOffset * itemsPerPage;

  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const pageCount = total_data && Math.ceil(total_data / itemsPerPage);
  // const currentItems =
  //   data_Flt && data_Flt.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageClick = (event) => {
    SetTrackListNum(event.selected);
    setTableStartIndex(event.selected * itemsPerPage);
    setData({
      ...valueData,
      sort: keyData,
      startIndex: event.selected * itemsPerPage,
    });
  };

  const data_list = data_Flt && data_Flt;
   const oneditThHeader = () =>{
    const isCondition = data_list?.some((data) => {if (Number(data?.created_By) !== 0) {
      return Number(data?.created_By) === Number(USERID);
    }
    return false;});
     if(isCondition || isAdmin){
      setIsEditHeadVisible(true);
     }
     else{
      setIsEditHeadVisible(false);
     }
   }
  // console.log("data_list => ",data_list)

  // padination  end

// console.log("isAdmin",isAdmin)
  //edit icon check
  const editIconCheck = (data) =>{
    if(isAdmin){
      return     <td className="cl_edit_option">
      <svg
      onClick={()=> onEditClickHandler(data)}
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="30"
          height="30"
          rx="10"
          fill="#F4F7FE"
        />
        <g clipPath="url(#clip0_1204_388)">
          <path
            d="M8.41051 17.5679L17.8261 8.1523L21.8546 12.1808L12.439 21.5964L8.41051 17.5679ZM18.2594 10.423L17.8179 9.98216L10.2397 17.5597L10.6812 18.0012L18.2594 10.423ZM19.1424 11.306L18.7009 10.8645L11.1227 18.4433L11.5636 18.8842L19.1424 11.306ZM20.0254 12.189L19.5839 11.7475L12.0057 19.3256L12.4472 19.7671L20.0254 12.189ZM19.6294 6.37367C20.1234 5.87841 20.9229 5.87525 21.4131 6.36545L23.6345 8.58683C24.1247 9.07703 24.1215 9.8759 23.6269 10.3705L22.2543 11.7431L18.2568 7.74559L19.6294 6.37367ZM8.10058 18.0922L11.9146 21.9063L8.57243 23.0935L6.91335 21.4344L8.10058 18.0922ZM7.93929 23.3004L6 24.0069L6.70652 22.0676L7.93929 23.3004Z"
            fill="#B5BF00"
          />
        </g>
        <defs>
          <clipPath id="clip0_1204_388">
            <rect
              width="19.4595"
              height="19.4595"
              fill="white"
              transform="translate(4.86475 5.67566)"
            />
          </clipPath>
        </defs>
      </svg>
    </td>
    }
    else{
      if( (Number(data?.created_By) ===  Number(USERID)) && Number(data?.created_By)!== 0 && Number(USERID) !==0 ){

        // setIsEditHeadVisible(true)
           return   <td className="cl_edit_option">
           <svg
             onClick={()=> onEditClickHandler(data)}
             width="30"
             height="30"
             viewBox="0 0 30 30"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
           >
             <rect
               width="30"
               height="30"
               rx="10"
               fill="#F4F7FE"
             />
             <g clipPath="url(#clip0_1204_388)">
               <path
                 d="M8.41051 17.5679L17.8261 8.1523L21.8546 12.1808L12.439 21.5964L8.41051 17.5679ZM18.2594 10.423L17.8179 9.98216L10.2397 17.5597L10.6812 18.0012L18.2594 10.423ZM19.1424 11.306L18.7009 10.8645L11.1227 18.4433L11.5636 18.8842L19.1424 11.306ZM20.0254 12.189L19.5839 11.7475L12.0057 19.3256L12.4472 19.7671L20.0254 12.189ZM19.6294 6.37367C20.1234 5.87841 20.9229 5.87525 21.4131 6.36545L23.6345 8.58683C24.1247 9.07703 24.1215 9.8759 23.6269 10.3705L22.2543 11.7431L18.2568 7.74559L19.6294 6.37367ZM8.10058 18.0922L11.9146 21.9063L8.57243 23.0935L6.91335 21.4344L8.10058 18.0922ZM7.93929 23.3004L6 24.0069L6.70652 22.0676L7.93929 23.3004Z"
                 fill="#B5BF00"
               />
             </g>
             <defs>
               <clipPath id="clip0_1204_388">
                 <rect
                   width="19.4595"
                   height="19.4595"
                   fill="white"
                   transform="translate(4.86475 5.67566)"
                 />
               </clipPath>
             </defs>
           </svg>
         </td>
      }
      else{

        return ;
      }
    }
    
  }
  //oneditClick handler
  const onEditClickHandler = (data) => {
    setEditId(data.id)
    setIsEdit(true)
        // material type multi select
  //  setMultiFormSelectedOption([...formMultiSelectedOption,])

    // console.log("data",data)
  // donor form details
  // material type multi select
setMultiFormSelectedOption(  apiMaterialList(data));
  // soiltreatment
  setSiteMapValues({
    ...siteMapData,
    map_latitude: data.position[0],
    map_longitude: data.position[1],
  })
  setSiteContactValues({
    ...siteContactData,
    name: data.name,
    email: data.email,
    company: data.company,
    agreement: data.agreement,
    consent: data.consent,
  })
  switch (data.type) {
    case "donor":
      setSiteValues({
        ...siteData,
        submitted_date:  data.submitted_date,
        address: data.address,
        notes: data.notes,
        quantity: data.quantity,
        available_from: data.available_from,
        available_to: data.available_to,
        material_type: "",
        specified_end_user: data.specified_end_user,
      });
   
       setTimeout(()=>{
        navigate(`register-of-materials/donor/${data.ref}?rootName=donor`)
       },400)
      break;
      case "receiver":
        setSiteValues({
          ...siteData,
          submitted_date:  data.submitted_date,
          address: data.address,
          notes: data.notes,
          quantity: data.quantity,
          available_from: data.available_from,
          available_to: data.available_to,
          material_type: "",
          specified_end_user: data.specified_end_user,
        });
       setTimeout(()=>{
        navigate(`register-of-materials/donor/${data.ref}?rootName=recevier`)
       },400)
      break;
      case "soil_treatment":
        setSoilValues({...soilData,
          submitted_date: data.submitted_date,
          address: data.address,
          yearly_intake: data.yearly_intake,
          treatable_materails: data.treatable_materails,
          timescale: data.timescale,
          notes:data.notes
        });
        setTimeout(()=>{
          navigate(`register-of-materials/donor/${data.ref}?rootName=soil_treatment`)
        },400)
       
      break;
  
    default:
      break;
  }
  
  }
  // console.log("siteContaat",siteContactData)
  return (
    <section className="filter_table_mastercontainer">
      <div
        className="ft_wrapper"
        style={{
          overflowX: data_Flt && data_Flt.length === 0 ? "hidden" : "auto",
          minHeight: open ? "288px" : "0px",
        }}
      >
        {/* table */}
        <table className="ft" ref={fl_table}>
          <thead>
            {tabletype === "donor" ? (
              <tr>
                <th onClick={() => onSortKey("ref")}>
                  #REF
                  <a onClick={() => onSortKey("ref")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/* Date Submitted*/}
                <th>
                  Date <br />
                  Submitted
                  <a onClick={() => onSortKey("submitted_date")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/* location */}
                <th>
                  Location
                  <a onClick={() => onSortKey("address")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/*  Quantity Available*/}
                <th>
                  Quantity <br />
                  Available
                  <a onClick={() => onSortKey("quantity")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/* Available from */}
                <th>
                  Available <br />
                  from
                  <a onClick={() => onSortKey("available_from")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/* Available to */}
                <th>
                  Available <br /> to
                  <a onClick={() => onSortKey("available_to")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/*  Material type*/}
                <th>
                  Material <br />
                  <div className="mtfilterblk">
                    type
                    <span className="filter_sec" style={{ marginLeft: "4px" }}>
                      <FilterIcon open={open} setopen={setopen}>
                        <MtDropdown
                          options={filterOption}
                          setopen={setopen}
                          setFilterOptionData={setFilterOptionData}
                          filterOptionData={filterOptionData}
                          open={open}
                        />
                      </FilterIcon>
                    </span>
                    <a onClick={() => onSortKey("material_type")}>
                      <span>
                        <svg
                          width="7"
                          height="11"
                          viewBox="0 0 7 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ft_up_arrow"
                        >
                          <path
                            d="M3.71803 1.00049V10.079"
                            stroke="#3B3F4C"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1 3.73111L3.71852 1L6.43704 3.73111"
                            stroke="#3B3F4C"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <svg
                          width="7"
                          height="11"
                          viewBox="0 0 7 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ft_down_arrow"
                        >
                          <path
                            d="M3.71803 1.00049V10.079"
                            stroke="#3B3F4C"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1 3.73111L3.71852 1L6.43704 3.73111"
                            stroke="#3B3F4C"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </th>
                {/*  Note*/}
                <th className="cl_notes">
                  Notes
                  <a onClick={() => onSortKey("notes")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
              {isEditHeadVisible && <th className="cl_edit_header"></th> }  
              </tr>
            ) : tabletype === "receiver" ? (
              <tr>
                <th>
                  #REF
                  <a onClick={() => onSortKey("ref")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/* Date Submitted*/}
                <th>
                  Date <br />
                  Submitted
                  <a onClick={() => onSortKey("submitted_date")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/* location */}
                <th>
                  Location
                  <a onClick={() => onSortKey("address")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/*  Quantity Needed*/}
                <th>
                  Quantity <br />
                  Needed
                  <a onClick={() => onSortKey("quantity")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/* Required  from */}
                <th>
                  Required <br />
                  from
                  <a onClick={() => onSortKey("available_from")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/* Required  to */}
                <th>
                  Required <br />
                  to
                  <a onClick={() => onSortKey("available_to")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/*  Material type*/}
                <th>
                  Material <br />
                  <div className="mtfilterblk">
                    type
                    <span className="filter_sec" style={{ marginLeft: "4px" }}>
                      <FilterIcon open={open} setopen={setopen}>
                        <MtDropdown
                          options={filterOption}
                          setopen={setopen}
                          setFilterOptionData={setFilterOptionData}
                          filterOptionData={filterOptionData}
                          open={open}
                        />
                      </FilterIcon>
                    </span>
                    <a onClick={() => onSortKey("material_type")}>
                      <span>
                        <svg
                          width="7"
                          height="11"
                          viewBox="0 0 7 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ft_up_arrow"
                        >
                          <path
                            d="M3.71803 1.00049V10.079"
                            stroke="#3B3F4C"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1 3.73111L3.71852 1L6.43704 3.73111"
                            stroke="#3B3F4C"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <svg
                          width="7"
                          height="11"
                          viewBox="0 0 7 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ft_down_arrow"
                        >
                          <path
                            d="M3.71803 1.00049V10.079"
                            stroke="#3B3F4C"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1 3.73111L3.71852 1L6.43704 3.73111"
                            stroke="#3B3F4C"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </th>
                {/*  Specified End Use*/}
                <th>
                  Specified <br />
                  End Use
                  <a onClick={() => onSortKey("specified_end_user")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/*  Note*/}
                <th className="cl_notes">
                  Notes
                  <a onClick={() => onSortKey("notes")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {isEditHeadVisible && <th className="cl_edit_header"></th> }  
              </tr>
            ) : tabletype === "soil_treatment" ? (
              <tr>
                <th>
                  #REF
                  <a onClick={() => onSortKey("ref")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/* Date Submitted*/}
                <th>
                  Date <br />
                  Submitted
                  <a onClick={() => onSortKey("submitted_date")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/* location */}
                <th>
                  Location
                  <a onClick={() => onSortKey("address")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/*  Yearly Intake*/}
                <th>
                  Yearly Intake
                  <a onClick={() => onSortKey("yearly_intake")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/* Traetable Material /Contaminants */}
                <th style={{ minWidth: "200px" }}>
                  Treatable <br />
                  Material /Contaminants
                  <a onClick={() => onSortKey("treatable_materails")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/*  Note*/}
                <th className="cl_notes">
                  Notes
                  <a onClick={() => onSortKey("notes")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {isEditHeadVisible && <th className="cl_edit_header"></th> }  
              </tr>
            ) : (
              <tr>
                <th>
                  #REF
                  <a onClick={() => onSortKey("ref")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/* Date Submitted*/}
                <th>
                  Date <br />
                  Submitted
                  <a onClick={() => onSortKey("submitted_date")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/* location */}
                <th>
                  Location
                  <a onClick={() => onSortKey("address")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/*  Quantity Available*/}
                <th>
                  Quantity <br />
                  Available
                  <a onClick={() => onSortKey("quantity")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/* Available from */}
                <th>
                  Available <br />
                  from
                  <a onClick={() => onSortKey("available_from")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/* Available to */}
                <th>
                  Available <br />
                  to
                  <a onClick={() => onSortKey("available_to")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {/*  Material type*/}
                <th>
                  Material <br />
                  <div className="mtfilterblk">
                    type
                    <span className="filter_sec" style={{ marginLeft: "4px" }}>
                      <FilterIcon open={open} setopen={setopen}>
                        <MtDropdown
                          options={filterOption}
                          setopen={setopen}
                          setFilterOptionData={setFilterOptionData}
                          filterOptionData={filterOptionData}
                          open={open}
                        />
                      </FilterIcon>
                    </span>
                    <a onClick={() => onSortKey("material_type")}>
                      <span>
                        <svg
                          width="7"
                          height="11"
                          viewBox="0 0 7 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ft_up_arrow"
                        >
                          <path
                            d="M3.71803 1.00049V10.079"
                            stroke="#3B3F4C"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1 3.73111L3.71852 1L6.43704 3.73111"
                            stroke="#3B3F4C"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <svg
                          width="7"
                          height="11"
                          viewBox="0 0 7 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ft_down_arrow"
                        >
                          <path
                            d="M3.71803 1.00049V10.079"
                            stroke="#3B3F4C"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1 3.73111L3.71852 1L6.43704 3.73111"
                            stroke="#3B3F4C"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </th>
                {/*  Note*/}
                <th className="cl_notes">
                  Notes
                  <a onClick={() => onSortKey("notes")}>
                    <span>
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_up_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ft_down_arrow"
                      >
                        <path
                          d="M3.71803 1.00049V10.079"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 3.73111L3.71852 1L6.43704 3.73111"
                          stroke="#3B3F4C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </th>
                {isEditHeadVisible && <th className="cl_edit_header"></th> }  
              </tr>
            )}
          </thead>
          {/* body */}
          <tbody>
            {data_list &&
              data_list.map((data, i) => {
                // let datefromdata = new Date(data.available_from)
                //   .toISOString()
                //   .split("T")[0];
                // let datetodata = new Date(data.available_to)
                //   .toISOString()
                //   .split("T")[0];
                //   let submitedatetodata = new Date(data.submitted_date)
                //   .toISOString()
                //   .split("T")[0];
                let datefromdata = dayjs(data.available_from).format(
                  "DD-MM-YYYY"
                );
                let datetodata = dayjs(data.available_to).format("DD-MM-YYYY");
                let submitedatetodata = dayjs(data.submitted_date).format(
                  "DD-MM-YYYY"
                );
                return (
                  // {/* row start */}
                  <React.Fragment key={i}>
                    {tabletype === "donor" ? (
                      <tr key={data.ref}>
                        <td
                          className="linkBlk"
                          onClick={() => {
                            onHandleChange(data.ref);
                          }}
                        >
                          {data.ref}
                        </td>
                        <td>{submitedatetodata}</td>
                        <td>{data.address}</td>
                        <td>{data.quantity}</td>
                        <td>{datefromdata}</td>
                        <td>{datetodata}</td>
                        <td>{data.material_type}</td>
                        <td className="cl_notes">{data.notes}</td>
                        {editIconCheck(data)}
                      
                      </tr>
                    ) : tabletype === "receiver" ? (
                      <tr key={data.ref}>
                        <td
                          className="linkBlk"
                          onClick={() => {
                            onHandleChange(data.ref);
                          }}
                        >
                          {data.ref}
                        </td>
                        <td>{submitedatetodata}</td>
                        <td>{data.address}</td>
                        <td>{data.quantity}</td>
                        <td>{datefromdata}</td>
                        <td>{datetodata}</td>
                        <td>{data.material_type}</td>
                        <td>{data.specified_end_user}</td>
                        <td className="cl_notes">{data.notes}</td>
                        {editIconCheck(data)}
                      </tr>
                    ) : tabletype === "soil_treatment" ? (
                      <tr key={data.ref}>
                        <td
                          className="linkBlk"
                          onClick={() => {
                            onHandleChange(data.ref);
                          }}
                        >
                          {data.ref}
                        </td>
                        <td>{submitedatetodata}</td>
                        <td>{data.address}</td>
                        <td>{data.yearly_intake}</td>
                        <td style={{ minWidth: "200px" }}>
                          {data.treatable_materails}
                        </td>
                        <td className="cl_notes">{data.notes}</td>
                        {editIconCheck(data)}
                      </tr>
                    ) : (
                      <tr key={data.ref}>
                        <td
                          className="linkBlk"
                          onClick={() => {
                            onHandleChange(data.ref);
                          }}
                        >
                          {data.ref}
                        </td>
                        <td>{submitedatetodata}</td>
                        <td>{data.address}</td>
                        <td>{data.quantity}</td>
                        <td>{datefromdata}</td>
                        <td>{datetodata}</td>
                        <td>{data.material_type}</td>
                        <td className="cl_notes">{data.notes}</td>
                        {editIconCheck(data)}
                      </tr>
                    )}
                  </React.Fragment>
                  //   {/* row end */}
                );
              })}
          </tbody>
        </table>
        {data_Flt && data_Flt.length === 0 && (
          <div className="no_data_found">No Matching Data is Found</div>
        )}
        {/* table end */}
      </div>
      <div className="fl_pagination">
        <div>
          <span style={{ whiteSpace: "nowrap" }}>
            Showing rows{" "}
            <DropDown
              ispagination={true}
              options={options}
              setItemsPerPage={setItemsPerPage}
            />
          </span>
          {/* <span className="fl_total_count"> out of {data_Flt && data_Flt.length}</span> */}
        </div>
        {/* <ReactPaginate
          //  key={count}
          breakLabel="..."
         
          nextLabel={
            pageCount &&  pageCount > 1 ? (<svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.666016 1.33317L5.33268 5.99984L0.666016 10.6665"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>) : ( "" )
          }
          forcePage={eventSel}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={ pageCount}
          previousLabel={
            itemOffset > 1 ? (
              <svg
                width="6"
                height="12"
                viewBox="0 0 6 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33398 10.6668L0.667318 6.00016L5.33398 1.3335"
                  stroke="#888888"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              ""
            )
          }
          renderOnZeroPageCount={null}
          containerClassName="pagination-con"
          pageLinkClassName={`pagination-a ${pageCount && pageCount === 1 ? 'pagination-active' : ''}`}
          previousLinkClassName="pagination-prev-a"
          nextLinkClassName="pagination-next-a"
          activeLinkClassName="pagination-active"
          pageClassName={`pagination-li ${ pageCount && pageCount === 1 ? 'selected' : ''}`}
          previousClassName={itemOffset > 1 ? "pagination-prev" : ""}
          nextClassName={`pagination-next ${pageCount && pageCount === 1 ? 'disabled' : ''}`}
        /> */}
        <ReactPaginate
          //  key={count}
          breakLabel="..."
          nextLabel={
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.666016 1.33317L5.33268 5.99984L0.666016 10.6665"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={
            valueData && valueData.startIndex > 0 ? (
              <svg
                width="6"
                height="12"
                viewBox="0 0 6 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33398 10.6668L0.667318 6.00016L5.33398 1.3335"
                  stroke="#888888"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              ""
            )
          }
          forcePage={valueData && valueData.startIndex === 0 ? 0 : trackListNum}
          renderOnZeroPageCount={null}
          containerClassName="pagination-con"
          pageLinkClassName={`pagination-a `}
          previousLinkClassName="pagination-prev-a"
          nextLinkClassName="pagination-next-a"
          activeLinkClassName="pagination-active"
          pageClassName={`pagination-li `}
          previousClassName={
            valueData && valueData.startIndex > 0 ? "pagination-prev" : ""
          }
          nextClassName={`pagination-next `}
        />
      </div>
    </section>
  );
};

export default FilterTable;
