import "./style.scss";

const Button = (props) => {
  const { variant, textContent, typeSubmit, onClick } = props;
  return (
    <button
      type={typeSubmit ? "submit" : "button"}
      className={
        variant === "normal_primary"
          ? "btn_normal_primary"
          : variant === "arrow_btn_primary"
          ? "btn_arrow_btn_primary"
          : variant === "normal_white"
          ? "btn_normal_white"
          : variant === "arrow_btn_white"
          ? "btn_arrow_btn_white" : "btn_normal_primary"

      }
      onClick={onClick}
    >
      {
        <>
          {textContent}
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_84_39146)">
                <path
                  d="M5 12H19"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 16L19 12"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 8L19 12"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_84_39146">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
        </>
      }
    </button>
  );
};

export default Button;
