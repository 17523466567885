import React from "react";
import TextField from '@mui/material/TextField';
import './style.scss';


const TextInput = props => {
  const { onChange,onKeyDown } = props;
  return (
    <div className="textInputTag">
      <span className="label">{props.label}</span>
      <TextField defaultValue={props.value} error={props.error} onChange={onChange} onKeyDown={onKeyDown} disabled={props.disabled} value={props.value} name={props.name} className="inputTag" id={props.name} />
      <div className="errorText">{props.error}</div>
    </div>
  );
};

export default TextInput;