import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './style.scss';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs(props) {
    const { handleChange, value, children, rootName } = props;
    let textValue;
    // useEffect(()=>{
        if(rootName === 'donor'){
            textValue = 'Donor';
        }else if(rootName === 'receiver'){
            textValue = 'Receiver';
        }else if(rootName === 'soil_treatment'){
            textValue = 'Soil Treatment Facilities';
        }
    // },[])
    return (
        <div className="Tabs">
            <ul className="nav">
                <li className={value == 0 ? 'active' : ""} onClick={() => handleChange(0)} {...a11yProps(0)}>{textValue} Site</li>
                <li className={value == 1 ? 'active' : ""} onClick={() => handleChange(1)} {...a11yProps(1)}>Map Coordinates</li>
                <li className={value == 2 ? 'active' : ""} onClick={() => handleChange(2)} {...a11yProps(2)}>Contact</li>
            </ul>
            <div className="outlet">
                {children}
            </div>
        </div>
    );
}