import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { validateDetail } from "../../utils/validation";
import "./style.scss";
import {
    ReconsoilBig,
    EUflagBig,
} from "../../assets/images";
import TextInput from '../../components/textInput';
import TextArea from '../../components/textArea';
import Button from '../../components/button';
import { useParams } from "react-router-dom";
import axios from "axios";
import { numHandleKeyPress } from "../../utils/validation";
import { SiteContext } from "../../utils/context";
import { useContext } from "react";
import Map from "../../components/map";
const sites = [
  {
    color: "#37A6D7",
    value: "Donor Sites",
    key: "donor",
  },
  {
    color: "#71AF26",
    value: "Receiver Sites",
    key: "receiver",
  },
  {
    color: "#583768",
    value: "Soil Treatment Sites",
    key: "soil_treatment",
  },
];

export default function DonorDetail() {
    const {userToken,setUserToken} = useContext(SiteContext);
    const [mapData, setMapData] = useState({});
    const navigate = useNavigate();
    const { ref } = useParams();
    const [detailForm, setDetailForm] = useState([]);
    const [formValues, setFormValues] = useState({
        ref: ref,
        receiver: '',
        name: '',
        telephone: '',
        message: '',
        consent:0,
        agreement:0,
    })
    const [formError, setFormError] = useState({});
    const [isSubmit, setIssubmit] = useState(false);
 

      const [activeMap, setActiveMap] = useState(
    "noType"
    //   {
    //   color: "#37A6D7",
    //   value: "Donor Sites",
    //   key: "donor",
    // }
  );
   
    const handleSubmit = () => {
        let datacheck = Object.values(formValues).every((value) => !!value);
        if (datacheck && formValues?.message.length >50) {
            const recipeUrl = `${process.env.REACT_APP_API_URL}receiver`;
            const requestMetadata = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization:
                    `Bearer ${JSON.parse(userToken)}`,
                },
                body: JSON.stringify(formValues),
            };
            fetch(recipeUrl,requestMetadata)
                .then((res) => res.json())
                .then((recipes) => {
                    if (recipes.code === 200) {
                        navigate("/register-of-materials");
                    }
                });
        } else {
            setFormError(validateDetail(formValues));
            setIssubmit(true);
        }

    }

    // api call for detail object
    const getApiCall = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}sites/${ref}`
            )
            .then((response) => {
                setDetailForm(response?.data?.data);
            });
    };

     const isAuth =sessionStorage.getItem("token");
    useEffect(()=>{

      setUserToken(isAuth);
    },[isAuth]);

    useEffect(() => {
        if (ref) {
            getApiCall();
        }

    }, [ref]);

      const getApiCallMap = (el) => {
    axios
      .get(`/api/v1/map?type=${el}`
      // , {
      //   headers: {
      //     Authorization:
      //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6IlZpamF5IiwiZW1haWwiOiJ2aWpheW90aGVyczEzQGdtYWlsLmNvbSIsImlhdCI6MTY3OTA1NzQ5NiwiZXhwIjoxNzEwNTkzNDk2fQ.bGqfuMdbYNDgD7oEflYPWvLrdn1b2AVJ5DcnosDdkM0",
      //   },
      // }
      )
      .then((response) => {
        setMapData({});
        setTimeout(() => {
          setMapData(response?.data?.data);
        }, 100);
      });
  };

      useEffect(() => {
    // map data
    axios
      .get(`${ process.env. REACT_APP_API_URL}map`,
      //  {
      //   headers: {
      //     Authorization:
      //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6IlZpamF5IiwiZW1haWwiOiJ2aWpheW90aGVyczEzQGdtYWlsLmNvbSIsImlhdCI6MTY3OTA1NzQ5NiwiZXhwIjoxNzEwNTkzNDk2fQ.bGqfuMdbYNDgD7oEflYPWvLrdn1b2AVJ5DcnosDdkM0",
      //   },
      // }
      )
      .then((response) => setMapData(response?.data?.data));
  }, []);

    
  const onClear = () => {
    setActiveMap("noType");
    axios
      .get(`${ process.env. REACT_APP_API_URL}map`,
      // {
      //   headers: {
      //     Authorization:
      //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6IlZpamF5IiwiZW1haWwiOiJ2aWpheW90aGVyczEzQGdtYWlsLmNvbSIsImlhdCI6MTY3OTA1NzQ5NiwiZXhwIjoxNzEwNTkzNDk2fQ.bGqfuMdbYNDgD7oEflYPWvLrdn1b2AVJ5DcnosDdkM0",
      //   },
      // }
      )
      .then((response) => {
        setMapData({});
        setTimeout(() => {
          setMapData(response?.data?.data);
        }, 100);
      });
  };

     const onFilter = (elem) => {
    if (elem?.key) {
      setActiveMap(elem);
      // setTimeout(() => {
      getApiCall(elem?.key);
      // }, 2000);
    }
  };
  // main dropdown options
  const mOptions = [
    { value: "Donor Site", label: "Donor Site", type: "donor" },
    { value: "Receiver Site", label: "Receiver Site ", type: "receiver" },
    {
      value: "Soil Treatment Facility",
      label: "Soil Treatment Facility",
      type: "soil_treatment",
    },
  ];

   

    return (
        <main className="cl_home_master_container ">
            {detailForm.length > 0 &&
                <>
			
                    <section className="hocl_home_master_container cl_container">

		         <section className="home_sec_location cl_container">
			<h3>Locations</h3>
			<div className="map_content_wrapper">
			  <Map
			    activeMap={activeMap}
			    data={Object.keys(mapData).length > 0 ? mapData : []}
			    sites={sites}
			    onClear={onClear}
			    onFilter={onFilter}
			  />

			</div>
	            </section> 
                       
                    <div className="siteName">Enquiry Form</div>
                    <div className="siteName_ref">{detailForm[0].type === "soil_treatment" ? 'Soil' : detailForm[0].type === "donor" ? 'Donor' : 'Receiver'} Site: {detailForm[0].ref}</div>
                        <div className="home_sec_hero_content_banner detailForm ">
             
                            <div className="blkContent lightBg">
                         
                                <div className="siteConent">
                                    <div className="siteHead">#Ref</div>
                                    <div>{detailForm[0].ref}</div>
                                </div>
                                <div className="siteConent">
                                    <div className="siteHead">Date Submitted</div>
                                    <div>{moment(detailForm[0].submitted_date).format('DD/MM/YYYY')}</div>
                                </div>
                                <div className="siteConent">
                                    <div className="siteHead">Location</div>
                                    <div>{detailForm[0].address}</div>
                                </div>
                                <div className="siteConent">
                                    <div className="siteHead">Quantity Available</div>
                                    <div>{detailForm[0].quantity}</div>
                                </div>
                                <div className="siteConent">
                                    <div className="siteHead">Available From</div>
                                    <div>{moment(detailForm[0].available_from).format('DD/MM/YYYY')}</div>
                                </div>
                                <div className="siteConent">
                                    <div className="siteHead">Available To</div>
                                    <div>{moment(detailForm[0].available_to).format('DD/MM/YYYY')}</div>
                                </div>
                                <div className="siteConent">
                                    <div className="siteHead">Material Type</div>
                                    <div>{detailForm[0].material_type}</div>
                                </div>
                            </div>
                            <div className="blkContent whiteBg">
                            
                                <form >
                                    <div className="contentForm">
                                        <TextInput
                                            onChange={(e) => {
                                                setFormValues({
                                                    ...formValues,
                                                    ref: detailForm[0].ref,
                                                });
                                            }}
                                            name={'refer'}
                                            value={formValues.ref}
                                            label={'Reference *'}
                                            disabled={"disabled"}
                                        />
                                    </div>
                                    <div className="contentForm">
                                        <TextInput
                                            onChange={(e) => {
                                                setFormValues({
                                                    ...formValues,
                                                    receiver: e.target.value,
                                                });
                                            }}
                                            name={'receiver'}
                                            error={formError.receiver}
                                            value={formValues.receiver}
                                            label={'Email *'} />
                                    </div>
                                    <div className="contentForm">
                                        <TextInput
                                            onChange={(e) => {
                                                setFormValues({
                                                    ...formValues,
                                                    name: e.target.value,
                                                });
                                            }}
                                            name={'name'}
                                            error={formError.name}
                                            value={formValues.name}
                                            label={'Name *'} />
                                    </div>
                                    <div className="contentForm">
                                        <TextInput
                                            onChange={(e) => {
                                                setFormValues({
                                                    ...formValues,
                                                    telephone: e.target.value,
                                                });
                                            }}
                                            onKeyDown={numHandleKeyPress}
                                            name={'phone'}
                                            error={formError.telephone}
                                            value={formValues.telephone}
                                            label={'Telephone *'} />
                                    </div>
                                    <div className="contentForm">
                                        {/* <TextInput
                                    onChange={(e) => {
                                        setFormValues({
                                            ...formValues,
                                            message: e.target.value,
                                        });
                                    }}
                                    error={formError.message}
                                    name={'message'}
                                    value={formValues.message}
                                    label={'Message *'} /> */}
                                        <TextArea onChange={(e) => {
                                            setFormValues({
                                                ...formValues,
                                                message: e.target.value,
                                            });
                                        }} value={formValues.message} error={formError.message} label={'Message *'} />
                                    </div>
                                    <div className="c_error_checkblk c_error_extra">
                <div className="agree_terms_policy ">
                  <span>
                    <input
                      type="checkbox"
                      id="term_policy"
                      name="Term"
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          agreement: e.target.checked ? 1 : 0,
                        });
                      }}
                    />
                  </span>
                  <p>
                    I agree to the
                    <a href="https://www.claire.co.uk/projects-and-initiatives/dow-cop/29-executing-dowcop-projects/115-terms-conditions"><span className="term"> DoWCoP Terms and Conditions</span></a>
                  </p>
                </div>
                <p className="c_check_error">{formError.agreement}</p>
              </div>
              {/* consent */}
              <div className="c_error_checkblk c_error_extra cdt_pb">
                <div className="agree_terms_policy cdt_pb">
                  <span>
                    <input
                      type="checkbox"
                      id="contactDetails_term_policy"
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          consent: e.target.checked ? 1 : 0,
                        });
                      }
                    }
                      name="contactDetailsTerm"
                    />
                  </span>
                  <p>
                    
                  I agree to share my contact details with the relevant site contact 
               
                  </p>
                </div>
                <p className="c_check_error">{formError.consent}</p>
              </div>
                                </form>
                                <Button
                                    variant="arrow_btn_primary"
                                    textContent="SEND"
                                    typeSubmit="button"
                                    onClick={handleSubmit}
                                />
                            </div>
                        </div>
	    </section></>}
        </main>
    );
}
