import "./style.scss";
import React, { useEffect, useState } from "react";
import {
  EUFlag,
  ReconsoilLogoGreen,
  BannerOne,
  Bannertwo,
  BannerThree,
  claire1,
  claire3,
  claire4,
} from "../../assets/images";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
// Components
import Button from "../../components/button";
import Map from "../../components/map";
import MainDropDown from "../../components/main_dropdown";
import FilterTable from "../../components/filtertable";
import TableDropdown from "../../components/tabledropdown";
import SearchInput from "../../components/input_search";
import MultiselDropdown from "../../components/multiseldropdown";
import { useContext } from "react";
import { SiteContext } from "../../utils/context";
//tab value
const sites = [
  {
    color: "#37A6D7",
    value: "Donor Sites",
    key: "donor",
  },
  {
    color: "#71AF26",
    value: "Receiver Sites",
    key: "receiver",
  },
  {
    color: "#583768",
    value: "Soil Treatment Sites",
    key: "soil_treatment",
  },
];

export default function Home() {
  const [listData, setListData] = useState({});
  const [mapData, setMapData] = useState({});
  const [donorType, setDonorType] = useState("donor");
  const [itemOffset, setItemOffset] = useState(1);
   const [itemsPerPage, setItemsPerPage] = useState(10); 
   const[tableStartIndex,setTableStartIndex]=useState(0);
  const [isSort, setSort] = useState(1);
  const [tabletype, setTableType] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const isAuth =sessionStorage.getItem("token");
    const {userToken,setUserToken} = useContext(SiteContext);
    useEffect(()=>{
      
      setUserToken(isAuth);
    },[isAuth])

  const [activeMap, setActiveMap] = useState(
    "noType"
    //   {
    //   color: "#37A6D7",
    //   value: "Donor Sites",
    //   key: "donor",
    // }
  );
  const [filterOptionData, setFilterOptionData] = useState([]);

  // filter table
  const [eventSel, setEvenSel] = useState(0);
  const material_type_options = [
    { value: "top_soil", label: "Top Soil" },
    { value: "sub_soil", label: "Sub Soil" },
    { value: "sand", label: "Sand" },
    { value: "gravel", label: "Gravel" },
    { value: "clay", label: "Clay" },
    { value: "aggregate", label: "Aggregate" },
  ];
  const [searchData, setSearchData] = useState("");
  const [keyData, setKeyData] = useState({
    key: "",
    value: "",
  });

  const [valueData, setData] = useState({
    recordCount: itemsPerPage,
    startIndex: 0,
    type: "donor",
    globalSearch: "",
  });
 useEffect(()=>{
  setData({
    ...valueData,
    startIndex:0,
recordCount: itemsPerPage  
  })
 },[itemsPerPage])
  useEffect(() => {
    if (searchData.length > 2) {
      setData({ ...valueData, startIndex:0 ,globalSearch: searchData.trim() });
    } else if (searchData.length < 2) {
      if (valueData.globalSearch !== "") {
        setData({ ...valueData, globalSearch: "" });
      }
    }
  }, [searchData]);
  useEffect(() => {
    if (filterOptionData.length > 0) {
      // setItemOffset(1);
      // setEvenSel(0);
      setData({
        ...valueData,
        startIndex:0,
        filter: filterOptionData.map((obj) => {
          return obj.label;
        }),
      });
    } else {
      if (valueData.hasOwnProperty("filter")) {
        setItemOffset(eventSel + 1);
        let newData = { ...valueData };
        delete newData.filter;
        setData(newData);
      }
    }
  }, [filterOptionData]);

  const onHandleChange = (ref) => {
    navigate(`/register-of-materials/donorDetail/${ref}`);
  };
  const onSortKey = (value) => {

    setKeyData({
      ...keyData,
      key: value,
      value: isSort === 1 ? -1 : 1,
    });
    setSort(isSort === 1 ? -1 : 1);
  };

  const getApiCall = (el) => {
    axios
      .get(`/api/v1/map?type=${el}`
      // , {
      //   headers: {
      //     Authorization:
      //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6IlZpamF5IiwiZW1haWwiOiJ2aWpheW90aGVyczEzQGdtYWlsLmNvbSIsImlhdCI6MTY3OTA1NzQ5NiwiZXhwIjoxNzEwNTkzNDk2fQ.bGqfuMdbYNDgD7oEflYPWvLrdn1b2AVJ5DcnosDdkM0",
      //   },
      // }
      )
      .then((response) => {
        setMapData({});
        setTimeout(() => {
          setMapData(response?.data?.data);
        }, 100);
      });
  };

  // useEffect(() => {
  //   getApiCall();
  // }, [donorType]);

  useEffect(() => {
    axios
      .post(
        `${process.env. REACT_APP_API_URL}sites/list`,
        { ...valueData, sort: keyData },
        // {
        //   headers: {
        //     Authorization:
        //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6IlZpamF5IiwiZW1haWwiOiJ2aWpheW90aGVyczEzQGdtYWlsLmNvbSIsImlhdCI6MTY3OTA1NzQ5NiwiZXhwIjoxNzEwNTkzNDk2fQ.bGqfuMdbYNDgD7oEflYPWvLrdn1b2AVJ5DcnosDdkM0",
        //   },
        // }
      )
      .then((response) => setListData(response));
  }, [keyData]);

  useEffect(() => {
    // table list
    axios
      .post( `${process.env. REACT_APP_API_URL}sites/list`, valueData,
      //  {
      //   headers: {
      //     Authorization:
      //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6IlZpamF5IiwiZW1haWwiOiJ2aWpheW90aGVyczEzQGdtYWlsLmNvbSIsImlhdCI6MTY3OTA1NzQ5NiwiZXhwIjoxNzEwNTkzNDk2fQ.bGqfuMdbYNDgD7oEflYPWvLrdn1b2AVJ5DcnosDdkM0",
      //   },
      // }
      )
      .then((response) => setListData(response));
  }, [valueData]);

  useEffect(() => {
    // map data
    axios
      .get(`${ process.env. REACT_APP_API_URL}map`,
      //  {
      //   headers: {
      //     Authorization:
      //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6IlZpamF5IiwiZW1haWwiOiJ2aWpheW90aGVyczEzQGdtYWlsLmNvbSIsImlhdCI6MTY3OTA1NzQ5NiwiZXhwIjoxNzEwNTkzNDk2fQ.bGqfuMdbYNDgD7oEflYPWvLrdn1b2AVJ5DcnosDdkM0",
      //   },
      // }
      )
      .then((response) => setMapData(response?.data?.data));
  }, []);

  const { data } = listData;
  const dataFetch = data?.data?.record;
  const total_data= data?.data?.total;

  //navigate to form
  function Handleformnavigate(value) {
    let token=sessionStorage.getItem("token")
    let siteUrl=sessionStorage.setItem("siteUrl",value);
    if(!!token){
      if (value === "Donor Site") {
        navigate("/register-of-materials/donor?rootName=donor");
      } else if (value === "Receiver Site") {
        navigate("/register-of-materials/donor?rootName=receiver");
      } else if (value === "Soil Treatment Facility") {
        navigate("/register-of-materials/donor?rootName=soil_treatment");
      }
    }
    else{
     navigate("/register-of-materials/signin");
    }
   

  }

  const onClear = () => {
    setActiveMap("noType");
    axios
      .get(`${ process.env. REACT_APP_API_URL}map`, 
      // {
      //   headers: {
      //     Authorization:
      //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6IlZpamF5IiwiZW1haWwiOiJ2aWpheW90aGVyczEzQGdtYWlsLmNvbSIsImlhdCI6MTY3OTA1NzQ5NiwiZXhwIjoxNzEwNTkzNDk2fQ.bGqfuMdbYNDgD7oEflYPWvLrdn1b2AVJ5DcnosDdkM0",
      //   },
      // }
      )
      .then((response) => {
        setMapData({});
        setTimeout(() => {
          setMapData(response?.data?.data);
        }, 100);
      });
  };

  const onFilter = (elem) => {
    if (elem?.key) {
      setDonorType(elem?.key);
      setActiveMap(elem);
      // setTimeout(() => {
      getApiCall(elem?.key);
      // }, 2000);
    }
  };
  // main dropdown options
  const mOptions = [
    { value: "Donor Site", label: "Donor Site", type: "donor" },
    { value: "Receiver Site", label: "Receiver Site ", type: "receiver" },
    {
      value: "Soil Treatment Facility",
      label: "Soil Treatment Facility",
      type: "soil_treatment",
    },
  ];

  //table dropdown handler
  const tdClickHandler = (type) => {
  
    setData({ ...valueData,startIndex: 0,sort: {key: "", value: ""}, type: type });
    setFilterOptionData([]);
  };

  return (
    <main className="cl_home_master_container ">
      {/* Location section */}
      <section className="home_sec_location cl_container">
        <h3>Locations</h3>
        <div className="map_content_wrapper">
          <Map
            activeMap={activeMap}
            data={Object.keys(mapData).length > 0 ? mapData : []}
            sites={sites}
            onClear={onClear}
            onFilter={onFilter}
          />
         
        </div>
        <p className="home_title_sub_content">
        </p>
        <div className="hshc_btn_wrapper">
            <MainDropDown options={mOptions} onclick={Handleformnavigate} />
        </div>
      </section>
      {/* subscribe section */}
      {/* <section className="home_subscribe_sec">
        <div className="home_subscribe_sec_wrapper cl_container">
          <div>
            <h2>
              Subscribe to our newsletter to stay up-to-date on the latest news
              and updates
            </h2>
            <p>
              To subscribe, simply enter your email address in the form below
              and click "subscribe". You'll receive a confirmation email shortly
              after, and then you'll be all set to receive our newsletter.
            </p>
          </div>
          <div>
            <Button
              variant="arrow_btn_primary"
              textContent="SUBSCRIBE NOW"
              typeSubmit="button"
            // onClick={""}
            />
          </div>
        </div>
      </section> */}
      {/* Donar sites */}
      <section className="home_donar_site cl_container">
        <h3>Available Sites</h3>
        <p className="home_title_sub_content">
	You will need to be logged in to submit a site or to make an enquiry. Please click the reference number to submit an enquiry about a listing.
        </p>
    
      {/* sessionStorage.getItem("token") ? "" :  <Button
          variant="normal_primary"
          textContent="SIGN IN / SIGN UP"
          typeSubmit="button"
          onClick={()=>{
           navigate("/register-of-materials/signin")
          }}
        /> */}
        <div className="h_table_noteblk">
          <p className="home_title_sub_content htsc_mb">
            <span><b>User Tip:</b></span> Click on any column heading to sort the list.
          </p>
          <div className="search_table_blk">
            <SearchInput
              setSearchData={setSearchData}
              searchData={searchData}
              valueData={valueData}
              setData={setData}
            />
            <TableDropdown
              options={mOptions}
              tdClickHandler={tdClickHandler}
              setTableType={setTableType}
            />
          </div>
        </div>

        {/* <button
          type="button"
          onClick={() => {
            setData({ ...valueData, type: "receiver" });
          }}
          style={{ margin: "10px" }}
        >
          change receiver data
        </button>
        <button
          type="button"
          onClick={() => {
            setData({ ...valueData, type: "soil_treatment" });
          }}
          style={{ margin: "10px" }}
        >
          change soil_treatment data
        </button>
        <button
          type="button"
          onClick={() => {
            setData({ ...valueData, type: "donor" });
          }}
          style={{ margin: "10px" }}
        >
          change donor data
        </button> */}
        {/* filter map */}
        <FilterTable
          setItemOffset={setItemOffset}
          itemOffset={itemOffset}
          data_Flt={dataFetch}
          total_data={total_data}
          onHandleChange={onHandleChange}
          onSortKey={onSortKey}
          tabletype={tabletype}
          filterOptionData={filterOptionData}
          setFilterOptionData={setFilterOptionData}
          valueData={valueData}
          setData={setData}
          itemsPerPage={itemsPerPage}
           setItemsPerPage={ setItemsPerPage}
           tableStartIndex={tableStartIndex}
           setTableStartIndex={setTableStartIndex}
           keyData={keyData}
        />
      </section>
      {/* DoW CoP Articles */}
      {/* <section className="home_sec_dow_cop_articles cl_container">
        <h2>DoW CoP Articles</h2>
        <div className="home_sec_dow_cop_articles_wrapper">
          <ListLinks linksobj={linkstestoneobj} />
          <ListLinks linksobj={linkstesttwoobj} />
          <ListLinks linksobj={linkstestthreeobj} />
          <ListLinks linksobj={linkstestfourobj} />
        </div>
      </section> */}
      {/* <MultiselDropdown options={material_type_options} /> */}
    </main>
  );
}
