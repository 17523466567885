import "./App.scss";
// LAYOUT COMPONENT
import Layout from "./layout";

function App() {
  return (
    <div className="cl_app_master_container">
      <Layout />
    </div>
  );
}

export default App;
