import { useState } from "react";
import "./style.scss";


const MainDropDown = (props) => {
         const {options,onclick} = props;
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Submit site");
     
    const handleOptionSelect = (option) => {
      setSelectedOption(option);
      setIsOpen(false);
    };
  
    return (
      <div
        className="md_dropdown_container"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <div className="md_dropdown_header" style={ {borderRadius: isOpen ? "8px 8px 0 0" :""}}>
          {selectedOption }
        </div>
        {
          <ul className={`${ isOpen ? "md_dropdown_options open" : "md_dropdown_options"}`}>
            {options.map((option) => (
              <li key={option.value} onClick={() =>{ handleOptionSelect(option.value);
              onclick(option.value);
              }
              }>
                {option.label}
              </li>
            ))}
          </ul>
        }
      </div>
    );
};

export default MainDropDown;