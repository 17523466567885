import React, { Suspense, lazy, useEffect } from "react";
import "./style.scss";
// React Router Bom
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
//Component
import Header from "../components/header";
import Footer from "../components/footer";
// PAGES
import Home from "../pages/home";
import DonorDetail from "../pages/donorDetail";
import Donor from "../pages/donor";
import ScrollToTop from "../components/scrolltoTop";
import SignUp from "../pages/signup";
import SignIn from "../pages/signin";
import {SiteContext} from "../utils/context"
import { useState } from "react";
import { dateFormatter } from "../utils";
import dayjs from "dayjs";
import MobSideBar from "../components/sideBar";
const Layout = () => {
  const [userToken,setUserToken]=useState(sessionStorage.getItem("token"));
  // admin check;
const [isAdmin,setIsAdmin]=useState(false);
// edit page
const [isEdit,setIsEdit]= useState(false);

  const today = dayjs(); // Get today's date
  // edit id
  const [editId,setEditId]=useState(null);
  // Mob sideBar state
  const [isMobSidebarVisible,setIsMobSidebarVisible]=useState(false);
  // material type multi select
  const [formMultiSelectedOption, setMultiFormSelectedOption] = useState([]);
  // donor form details
  
  const [soilData, setSoilValues] = useState({
    submitted_date: "",
    address: "",
    yearly_intake: "",
    treatable_materails: "",
    timescale: "",
    notes:""
  });
 
  const [siteData, setSiteValues] = useState({
    submitted_date:  dateFormatter(today),
    address: "",
    notes: "",
    quantity: "",
    available_from: "",
    available_to: "",
    material_type: "",
    specified_end_user: "",
  });

  const [siteMapData, setSiteMapValues] = useState({
    map_latitude: "",
    map_longitude: "",
  });
  const [siteContactData, setSiteContactValues] = useState({
    name: "",
    email: "",
    company: "",
    agreement: 0,
    consent: 0,
  });
  useEffect(() => {
    document.body.style.overflow = 'unset';
    if (isMobSidebarVisible) {
   
        document.body.style.overflow = 'hidden';
    }
 }, [isMobSidebarVisible]);
  return (
    <>
        <SiteContext.Provider value={{userToken,setUserToken,formMultiSelectedOption,setMultiFormSelectedOption,soilData,setSoilValues,siteData,setSiteValues,siteMapData, setSiteMapValues,siteContactData, setSiteContactValues,isAdmin,setIsAdmin,isEdit,setIsEdit,editId,setEditId,isMobSidebarVisible,setIsMobSidebarVisible}}>
        <BrowserRouter>
          <ScrollToTop />
          <div className={`master_wrapper `}>
            { isMobSidebarVisible && <div className="mob_overlay" style={{opacity:isMobSidebarVisible ? "1" : "0" }} onClick={()=>{setIsMobSidebarVisible(false)}}></div>}
          <div className="mob_sidebar" ><MobSideBar/></div> 

          <div className="main-page" style={{transform:isMobSidebarVisible ? "translateX(280px)" : "translateX(0px)" }}>
	  {/*<Header />*/}            
          <Routes>
            <Route path="/register-of-materials" element={<Home />} />
            <Route path="/register-of-materials/donor" element={<Donor />} />
            <Route path="/register-of-materials/donor/:ref" element={<Donor />} />
            <Route path="/register-of-materials/donorDetail/:ref" element={<DonorDetail />} />
            <Route path="/register-of-materials/signup" element={<SignUp />} />
          <Route path="/register-of-materials/signin" element={<SignIn />} />
          </Routes>
	  {/* <Footer /> */}
          </div>
          </div>
          
        </BrowserRouter>
        </SiteContext.Provider>
    </>
  );
};

export default Layout;
