import React, { useRef, useState } from "react";
import axios from "axios";
import "./style.scss";
import Button from "../../components/button";
import { supValidate } from "../../utils/validation";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const SignUp = () => {
          const navigate=  useNavigate();
          const prevSignUpData = useRef();
  const [register, setRegisterData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    organization: "",
    email: "",
    password: "",
    privacy_policy: 0,
    terms_and_conditions: 0,
    confirm_password: "",
    confirm_email: "",
  });
  // err
  const [regError, setRegError] = useState({});
  useEffect(()=>{
    if (Object.values(register).every((value) => !!value) && Object.keys(regError).length === 0){
      let postSignUpData={};
      for(let prop in register){
          if(prop !== "confirm_password" && prop !=="confirm_email" ){
            postSignUpData[prop] =register[prop]
          }
      }
   
      if (JSON.stringify(postSignUpData) !== JSON.stringify(prevSignUpData.current)) {
        axios.post(`${process.env. REACT_APP_API_URL}user`,postSignUpData).then((response) => { 
          setRegError("") ;  
          setRegisterData("");
            navigate("/register-of-materials/signin");  
        })
        .catch((err) =>{
          if(err.response.data.status ="error"){
            if(err.response.data.errorField === "email"){
              setRegError({email: err.response.data.message})
              }
             if(err.response.data.errorField === "username"){
              setRegError({username: err.response.data.message})
             }   
          }
       })
         
      // Store the current data as the previous data
      prevSignUpData.current = postSignUpData;
      }
    

    }
  },[regError]);
  const submitHandler = (e) => {
    e.preventDefault();
    setRegError(supValidate(register));
  };

  return (
    <main className="cl_container">
      <section className="cl_signup">
        <div className="cl_signup_wrapper">
          <h1>Sign Up</h1>
          <form
            className="cl_signup_form_wrapper"
            noValidate
            onSubmit={(e) => {
              submitHandler(e);
            }}
          >
            <div className="cl_siup_fields_wrapper">
              {/* first name */}
              <div className="cl_siup_formblk">
                <label htmlFor="siup_firstname">First name</label>
                <div className="cl_signup_inpblk">
                  <div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 3.75C11.0054 3.75 10.0516 4.14509 9.34835 4.84835C8.64509 5.55161 8.25 6.50544 8.25 7.5C8.25 8.49456 8.64509 9.44839 9.34835 10.1517C10.0516 10.8549 11.0054 11.25 12 11.25C12.9946 11.25 13.9484 10.8549 14.6517 10.1517C15.3549 9.44839 15.75 8.49456 15.75 7.5C15.75 6.50544 15.3549 5.55161 14.6517 4.84835C13.9484 4.14509 12.9946 3.75 12 3.75ZM8 13.25C7.00544 13.25 6.05161 13.6451 5.34835 14.3483C4.64509 15.0516 4.25 16.0054 4.25 17V18.188C4.25 18.942 4.796 19.584 5.54 19.705C9.818 20.404 14.182 20.404 18.46 19.705C18.8201 19.6464 19.1476 19.4616 19.3839 19.1837C19.6202 18.9057 19.75 18.5528 19.75 18.188V17C19.75 16.0054 19.3549 15.0516 18.6517 14.3483C17.9484 13.6451 16.9946 13.25 16 13.25H15.66C15.475 13.25 15.291 13.28 15.116 13.336L14.25 13.619C12.788 14.0962 11.212 14.0962 9.75 13.619L8.884 13.336C8.70865 13.2789 8.5254 13.2499 8.341 13.25H8Z"
                        fill="#B5BF00"
                      />
                    </svg>
                  </div>
                  <input
                    id="siup_firstname"
                    type="text"
                    name="firstname"
                    value={register.first_name}
                    onChange={(e) => {
                      setRegisterData({
                        ...register,
                        first_name: e.target.value,
                      });
                    }}
                    placeholder="First Name"
                    autoComplete="off"
                  />
                </div>
                <p className="cl_siup_error">{regError.first_name}</p>
              </div>
              {/* last Name */}
              <div className="cl_siup_formblk">
                <label htmlFor="siup_lastname">Last name</label>
                <div className="cl_signup_inpblk">
                  <div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 3.75C11.0054 3.75 10.0516 4.14509 9.34835 4.84835C8.64509 5.55161 8.25 6.50544 8.25 7.5C8.25 8.49456 8.64509 9.44839 9.34835 10.1517C10.0516 10.8549 11.0054 11.25 12 11.25C12.9946 11.25 13.9484 10.8549 14.6517 10.1517C15.3549 9.44839 15.75 8.49456 15.75 7.5C15.75 6.50544 15.3549 5.55161 14.6517 4.84835C13.9484 4.14509 12.9946 3.75 12 3.75ZM8 13.25C7.00544 13.25 6.05161 13.6451 5.34835 14.3483C4.64509 15.0516 4.25 16.0054 4.25 17V18.188C4.25 18.942 4.796 19.584 5.54 19.705C9.818 20.404 14.182 20.404 18.46 19.705C18.8201 19.6464 19.1476 19.4616 19.3839 19.1837C19.6202 18.9057 19.75 18.5528 19.75 18.188V17C19.75 16.0054 19.3549 15.0516 18.6517 14.3483C17.9484 13.6451 16.9946 13.25 16 13.25H15.66C15.475 13.25 15.291 13.28 15.116 13.336L14.25 13.619C12.788 14.0962 11.212 14.0962 9.75 13.619L8.884 13.336C8.70865 13.2789 8.5254 13.2499 8.341 13.25H8Z"
                        fill="#B5BF00"
                      />
                    </svg>
                  </div>
                  <input
                    id="siup_lastname"
                    name="lastname"
                    type="text"
                    placeholder="LastName"
                    value={register.last_name}
                    onChange={(e) => {
                      setRegisterData({
                        ...register,
                        last_name: e.target.value,
                      });
                    }}
                    autoComplete="off"
                  />
                </div>
                <p className="cl_siup_error">{regError.last_name}</p>
              </div>
              {/* username */}
              <div className="cl_siup_formblk">
                <label htmlFor="siup_username">Username</label>

                <div className="cl_signup_inpblk">
                  <div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 3.75C11.0054 3.75 10.0516 4.14509 9.34835 4.84835C8.64509 5.55161 8.25 6.50544 8.25 7.5C8.25 8.49456 8.64509 9.44839 9.34835 10.1517C10.0516 10.8549 11.0054 11.25 12 11.25C12.9946 11.25 13.9484 10.8549 14.6517 10.1517C15.3549 9.44839 15.75 8.49456 15.75 7.5C15.75 6.50544 15.3549 5.55161 14.6517 4.84835C13.9484 4.14509 12.9946 3.75 12 3.75ZM8 13.25C7.00544 13.25 6.05161 13.6451 5.34835 14.3483C4.64509 15.0516 4.25 16.0054 4.25 17V18.188C4.25 18.942 4.796 19.584 5.54 19.705C9.818 20.404 14.182 20.404 18.46 19.705C18.8201 19.6464 19.1476 19.4616 19.3839 19.1837C19.6202 18.9057 19.75 18.5528 19.75 18.188V17C19.75 16.0054 19.3549 15.0516 18.6517 14.3483C17.9484 13.6451 16.9946 13.25 16 13.25H15.66C15.475 13.25 15.291 13.28 15.116 13.336L14.25 13.619C12.788 14.0962 11.212 14.0962 9.75 13.619L8.884 13.336C8.70865 13.2789 8.5254 13.2499 8.341 13.25H8Z"
                        fill="#B5BF00"
                      />
                    </svg>
                  </div>
                  <input
                    id="siup_username"
                    name="username"
                    type="text"
                    placeholder="Username"
                    value={register.username}
                    onChange={(e) => {
                      setRegisterData({
                        ...register,
                        username: e.target.value,
                      });
                    }}
                    autoComplete="off"
                  />
                </div>
                {/* <p className="siup_note"><span>Note :</span> Lowercase only,no spaces and must not contain punctuation.</p> */}
                <p className="cl_siup_error">{regError.username}</p>
              </div>
              {/* organization */}
              <div className="cl_siup_formblk">
                <label htmlFor="siup_organisation">Organisation</label>

                <div className="cl_signup_inpblk">
                  <div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 3.75C11.0054 3.75 10.0516 4.14509 9.34835 4.84835C8.64509 5.55161 8.25 6.50544 8.25 7.5C8.25 8.49456 8.64509 9.44839 9.34835 10.1517C10.0516 10.8549 11.0054 11.25 12 11.25C12.9946 11.25 13.9484 10.8549 14.6517 10.1517C15.3549 9.44839 15.75 8.49456 15.75 7.5C15.75 6.50544 15.3549 5.55161 14.6517 4.84835C13.9484 4.14509 12.9946 3.75 12 3.75ZM8 13.25C7.00544 13.25 6.05161 13.6451 5.34835 14.3483C4.64509 15.0516 4.25 16.0054 4.25 17V18.188C4.25 18.942 4.796 19.584 5.54 19.705C9.818 20.404 14.182 20.404 18.46 19.705C18.8201 19.6464 19.1476 19.4616 19.3839 19.1837C19.6202 18.9057 19.75 18.5528 19.75 18.188V17C19.75 16.0054 19.3549 15.0516 18.6517 14.3483C17.9484 13.6451 16.9946 13.25 16 13.25H15.66C15.475 13.25 15.291 13.28 15.116 13.336L14.25 13.619C12.788 14.0962 11.212 14.0962 9.75 13.619L8.884 13.336C8.70865 13.2789 8.5254 13.2499 8.341 13.25H8Z"
                        fill="#B5BF00"
                      />
                    </svg>
                  </div>
                  <input
                    id="siup_organsation"
                    name="organisation"
                    type="text"
                    placeholder="Organisation"
                    value={register.organization}
                    onChange={(e) => {
                      setRegisterData({
                        ...register,
                        organization: e.target.value,
                      });
                    }}
                    autoComplete="off"
                  />
                </div>
                <p className="cl_siup_error">{regError.organization}</p>
              </div>
              {/* email  */}
              <div className="cl_siup_formblk">
                <label htmlFor="siup_email_address">Email Address</label>

                <div className="cl_signup_inpblk">
                  <div>
                    <svg
                      width="20"
                      height="16"
                      viewBox="0 0 20 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 7L2 2H18ZM18 14H2V4L10 9L18 4V14Z"
                        fill="##B5BF00"
                      />
                    </svg>
                  </div>
                  <input
                    id="siup_email_address"
                    name="emailaddress"
                    type="email"
                    placeholder="Email Address"
                    value={register.email}
                    onChange={(e) => {
                      setRegisterData({
                        ...register,
                        email: e.target.value,
                      });
                    }}
                    autoComplete="off"
                  />
                </div>
                <p className="cl_siup_error">{regError.email }</p>
              </div>
              {/* confirm email*/}
              <div className="cl_siup_formblk">
                <label htmlFor="siup_con_email_address">
                  Confirm Email Address
                </label>

                <div className="cl_signup_inpblk">
                  <div>
                    <svg
                      width="20"
                      height="16"
                      viewBox="0 0 20 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 7L2 2H18ZM18 14H2V4L10 9L18 4V14Z"
                        fill="##B5BF00"
                      />
                    </svg>
                  </div>
                  <input
                    id="siup_con_email_address"
                    name="confirmemailaddress"
                    type="email"
                    placeholder="Confirm Email Address"
                    onChange={(e) => {
                      setRegisterData({
                        ...register,
                        confirm_email: e.target.value,
                      });
                    }}
                    autoComplete="off"
                  />
                </div>
                <p className="cl_siup_error">{regError.confirm_email}</p>
              </div>
              {/* password */}
              <div className="cl_siup_formblk">
                <label htmlFor="siup_password">Password</label>
                <div className="cl_signup_inpblk">
                  <div>
                    <svg
                      width="16"
                      height="21"
                      viewBox="0 0 16 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 16C8.53043 16 9.03914 15.7893 9.41421 15.4142C9.78929 15.0391 10 14.5304 10 14C10 13.4696 9.78929 12.9609 9.41421 12.5858C9.03914 12.2107 8.53043 12 8 12C7.46957 12 6.96086 12.2107 6.58579 12.5858C6.21071 12.9609 6 13.4696 6 14C6 14.5304 6.21071 15.0391 6.58579 15.4142C6.96086 15.7893 7.46957 16 8 16ZM14 7C14.5304 7 15.0391 7.21071 15.4142 7.58579C15.7893 7.96086 16 8.46957 16 9V19C16 19.5304 15.7893 20.0391 15.4142 20.4142C15.0391 20.7893 14.5304 21 14 21H2C1.46957 21 0.960859 20.7893 0.585786 20.4142C0.210714 20.0391 0 19.5304 0 19V9C0 8.46957 0.210714 7.96086 0.585786 7.58579C0.960859 7.21071 1.46957 7 2 7H3V5C3 3.67392 3.52678 2.40215 4.46447 1.46447C5.40215 0.526784 6.67392 0 8 0C8.65661 0 9.30679 0.129329 9.91342 0.380602C10.52 0.631876 11.0712 1.00017 11.5355 1.46447C11.9998 1.92876 12.3681 2.47995 12.6194 3.08658C12.8707 3.69321 13 4.34339 13 5V7H14ZM8 2C7.20435 2 6.44129 2.31607 5.87868 2.87868C5.31607 3.44129 5 4.20435 5 5V7H11V5C11 4.20435 10.6839 3.44129 10.1213 2.87868C9.55871 2.31607 8.79565 2 8 2Z"
                        fill="##B5BF00"
                      />
                    </svg>
                  </div>
                  <input
                    id="siup_password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    onChange={(e) => {
                      setRegisterData({
                        ...register,
                        password: e.target.value,
                      });
                    }}
                    autoComplete="off"
                  />
                </div>
                <p className="cl_siup_error">{regError.password}</p>
              </div>
              {/* confirm password */}
              <div className="cl_siup_formblk">
                <label htmlFor="siup_cpassword">Confirm Password</label>
                <div className="cl_signup_inpblk">
                  <div>
                    <svg
                      width="16"
                      height="21"
                      viewBox="0 0 16 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 16C8.53043 16 9.03914 15.7893 9.41421 15.4142C9.78929 15.0391 10 14.5304 10 14C10 13.4696 9.78929 12.9609 9.41421 12.5858C9.03914 12.2107 8.53043 12 8 12C7.46957 12 6.96086 12.2107 6.58579 12.5858C6.21071 12.9609 6 13.4696 6 14C6 14.5304 6.21071 15.0391 6.58579 15.4142C6.96086 15.7893 7.46957 16 8 16ZM14 7C14.5304 7 15.0391 7.21071 15.4142 7.58579C15.7893 7.96086 16 8.46957 16 9V19C16 19.5304 15.7893 20.0391 15.4142 20.4142C15.0391 20.7893 14.5304 21 14 21H2C1.46957 21 0.960859 20.7893 0.585786 20.4142C0.210714 20.0391 0 19.5304 0 19V9C0 8.46957 0.210714 7.96086 0.585786 7.58579C0.960859 7.21071 1.46957 7 2 7H3V5C3 3.67392 3.52678 2.40215 4.46447 1.46447C5.40215 0.526784 6.67392 0 8 0C8.65661 0 9.30679 0.129329 9.91342 0.380602C10.52 0.631876 11.0712 1.00017 11.5355 1.46447C11.9998 1.92876 12.3681 2.47995 12.6194 3.08658C12.8707 3.69321 13 4.34339 13 5V7H14ZM8 2C7.20435 2 6.44129 2.31607 5.87868 2.87868C5.31607 3.44129 5 4.20435 5 5V7H11V5C11 4.20435 10.6839 3.44129 10.1213 2.87868C9.55871 2.31607 8.79565 2 8 2Z"
                        fill="##B5BF00"
                      />
                    </svg>
                  </div>
                  <input
                    id="siup_cpassword"
                    name="confirmpassword"
                    type="password"
                    placeholder="Confirm Password"
                    onChange={(e) => {
                      setRegisterData({
                        ...register,
                        confirm_password: e.target.value,
                      });
                    }}
                    autoComplete="off"
                  />
                </div>
                <p className="cl_siup_error">{regError.confirm_password}</p>
              </div>
              {/* privacy terms */}
              <div className="cl_siup_formblk">
                <div className="cl_check_wrapper">
                  <input
                    type="checkbox"
                    id="siup_privacy_policy"
                    name="privacy_policy"
                    onChange={(e) => {
                      setRegisterData({
                        ...register,
                        privacy_policy: e.target.checked ? 1 : 0,
                      });
                    }}
                  />
                  <label htmlFor="siup_privacy_policy">
                    I agree to the CL:AIRE Privacy Policy
                  </label>
                </div>
                <p className="cl_siup_error check_er">
                  {regError.privacy_policy }
                </p>
              </div>
              {/* terms_and_conditions */}
              <div className="cl_siup_formblk">
                <div className="cl_check_wrapper">
                  <input
                    type="checkbox"
                    id="siup_terms_and_conditions"
                    name="terms_and_conditions"
                    onChange={(e) => {
                      setRegisterData({
                        ...register,
                        terms_and_conditions: e.target.checked ? 1 : 0,
                      });
                    }}
                  />
                  <label htmlFor="siup_terms_and_conditions">
                    I agree to the Website Terms and Conditions
                  </label>
                </div>
                <p className="cl_siup_error check_er">
                  {regError.terms_and_conditions}
                </p>
              </div>
            </div>
            <Button
              variant="arrow_btn_primary"
              textContent="Register"
              typeSubmit="submit"
            />
          </form>
        </div>
      </section>
    </main>
  );
};

export default SignUp;
