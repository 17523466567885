import "./style.scss";

const SearchInput = (props) => {
  const {className,setSearchData,searchData} = props;
  return (
    <div className={`inp_search_wrapper ${className}`}>
      <input type="search" value={searchData} name="search" onChange={(e)=>{
            setSearchData(e.target.value);
      }} placeholder="Search" />
      <span className="inp_search_icon">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
            stroke="#888888"
            strokeWidth="1.5"
            strokeLinecap="square"
          />
          <path
            d="M13.9996 14.0006L11.0996 11.1006"
            stroke="#888888"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </span>
    </div>
  );
};

export default SearchInput;
