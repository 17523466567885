import React, { useEffect, useRef } from 'react';
import "./style.scss";
const FilterIcon = ( props) => {
    const{setopen,open}= props;
    let menuRef = useRef();
    useEffect(() => {
      let handler = (e)=>{
        if(!menuRef.current.contains(e.target)){
          setopen(false);
        }      
      };
      document.addEventListener("mousedown", handler);
      return() =>{
        document.removeEventListener("mousedown", handler);
      }
    });
    
    return (
        <div className='filter_icon_wrappper' ref={menuRef}>
        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{cursor:"pointer"}} className={open ? "svgfilter open":"svgfilter"} onClick={() =>{setopen(!open)}}>
        <path d="M1.76637 2.57209C1.26215 2.07379 1.01003 1.82463 1.00053 1.61288C0.992266 1.42894 1.08166 1.25214 1.2422 1.13493C1.42701 1 1.80511 1 2.5613 1H12.4566C13.2128 1 13.5909 1 13.7757 1.13493C13.9362 1.25214 14.0256 1.42894 14.0174 1.61288C14.0078 1.82463 13.7557 2.07379 13.2515 2.57209L9.44738 6.33158C9.34685 6.43091 9.29658 6.48061 9.26078 6.53714C9.22898 6.58725 9.20565 6.64124 9.19151 6.6973C9.17558 6.76056 9.17558 6.82723 9.17558 6.96051V10.1126C9.17558 10.2278 9.17558 10.2855 9.15458 10.3353C9.13598 10.3794 9.10578 10.4188 9.06645 10.4504C9.02191 10.4862 8.96138 10.5076 8.84038 10.5504L6.57371 11.3522C6.32865 11.4388 6.20611 11.4821 6.10779 11.464C6.02179 11.4482 5.94631 11.4031 5.89778 11.3383C5.84228 11.2643 5.84228 11.1476 5.84228 10.9143V6.96051C5.84228 6.82723 5.84228 6.76056 5.82636 6.6973C5.81224 6.64124 5.78892 6.58725 5.75714 6.53714C5.7213 6.48061 5.67105 6.43091 5.57053 6.33158L1.76637 2.57209Z" fill="#B5BF00" stroke="#B5BF00" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        {props.children}
        </div>
        
    );
};

export default FilterIcon;