import dayjs from "dayjs";
export const features = [
    {
        position: ["-33.91721", "151.2263"],
        type: "donor",
    },
    {
        position: ["-33.91662347903106", "151.22879464019775"],
        type: "receiver",
    },
    {
        position: ["-33.91727341958453", "151.23348314155578"],
        type: "soil",
    },
];
// iso format
export var iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
// date formator
export const dateFormatter = (newValue)=>{
    // Check if the date string matches the pattern
  // if( iso8601Pattern.test(newValue)){
  //   const dayjsModifiedNewValue=dayjs(newValue);
  //   const date = new Date(dayjsModifiedNewValue.toDate());   
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const day = String(date.getDate()).padStart(2, "0");
  //   return  `${year}-${month}-${day}`;
  // }
  // else{
const date = new Date(newValue.toDate());   
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return  `${year}-${month}-${day}`;
  // }
    
    }
