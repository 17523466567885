import React from "react";
import TextField from '@mui/material/TextField';
import './style.scss';


const TextArea = props => {
    const { onChange, error } = props;
    return (
        <div className={`textareaWrap ${error ? 'errorArea' : ''}`}>
            {/* <span className="label">{props.label}</span> */}
            <label>
                {props.label}
            </label>
            <textarea
                // id={postTextAreaId}
                name="postContent"
                onChange={onChange}
                rows={6}
                cols={40}
            />
            <div className="errorText">{error}</div>
            {/* <TextField defaultValue={props.value} error={props.error} onChange={onChange} value={props.value} name={props.name} className="inputTag" id={props.name} variant="outlined" /> */}
        </div>
    );
};

export default TextArea;