import React, { useContext } from "react";
import "./style.scss";
import { SiteContext } from "../../utils/context";
import { NavLink } from "react-router-dom";
import { FaGreaterThan } from "react-icons/fa";
import { useState } from "react";

const MobSideBar = () => {
  const { isMobSidebarVisible, setIsMobSidebarVisible } =
    useContext(SiteContext);
    const [isSubmenuActive,setIsSubmenuActive]=useState({
        1:false,
        2:false,
        3:false,
        4:false,
        5:false,
        6:false,

    })
  const onLinkClickHandler = () => {
    setIsMobSidebarVisible(false);
  };
  //on arrow click handler
  const onArrowClickHandler= (e,staticIndex) =>{
    e.preventDefault();
   
    setIsSubmenuActive((prev) => {return {...prev,[staticIndex]:true}})
  }
//   on back arrow click
const onBackArrowClick = () =>{
    setIsSubmenuActive({
        1:false,
        2:false,
        3:false,
        4:false,
        5:false,
        6:false,

    })
}
  return (
    <aside
      className="mob_sidebar_container"
      style={{ width: isMobSidebarVisible ? "280px" : "0" }}
    >
      <div
        className="mob_sidebar_wrapper no-scrollbar"
        style={{ width: isMobSidebarVisible ? "280px" : "0" }}
      >
        <div className="inner_wrapper no-scrollbar">
          <nav className="mob_nav no-scrollbar">
            <ul>
              <li>
                <NavLink to={"/"}>
                  <span onClick={onLinkClickHandler}>Home</span>
                  <span
                    onClick={(e) => {
                     onArrowClickHandler(e,1)
                    }}
                  >
                    <FaGreaterThan />{" "}
                  </span>
                </NavLink >
                {/* submenu */}
                <ul className={`mob_submenu ${isSubmenuActive[1] ? "mob_submenu_active" : "" }`}>
                <li>
                    <div className="back_block">
                    <FaGreaterThan onClick={onBackArrowClick} />
                    </div>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/home/news"}>
                      <span>New</span>
                    </NavLink>
                  </li>
                  <li>
                    {" "}
                    <NavLink to={"https://www.claire.co.uk/home/about-us"}>
                      <span>About Us</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/home/pledge-to-net-zero"}>
                      <span>Pledge to Net Zero</span>
                    </NavLink>
                  </li>
                  <li>
                    {" "}
                    <NavLink to={"https://www.claire.co.uk/home/latest-cl-aire-ealert"}>
                      <span>Latest CL:AIRE eAlert</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to={"https://www.claire.co.uk/membership"}>
                  <span onClick={onLinkClickHandler}>Membership</span>
                  <span
                    onClick={(e) => {
                     onArrowClickHandler(e,2)
                    }}
                  >
                    <FaGreaterThan />{" "}
                  </span>
                </NavLink>
                {/* submenu */}
                <ul className={`mob_submenu ${isSubmenuActive[2] ? "mob_submenu_active" : "" }`}>
                <li>
                    <div className="back_block">
                    <FaGreaterThan onClick={onBackArrowClick} />
                    </div>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/membership/about-membership"}>
                      <span>About Membership</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/membership/principal-member-profiles"}>
                      <span>Principal Member Profiles</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to={"https://www.claire.co.uk/events-training"}>
                  <span onClick={onLinkClickHandler}>Events & Training</span>
                  <span
                    onClick={(e) => {
                     onArrowClickHandler(e,3)
                    }}
                  >
                    <FaGreaterThan />
                  </span>
                </NavLink>
                {/* submenu */}
                <ul className={`mob_submenu ${isSubmenuActive[3] ? "mob_submenu_active" : "" }`}>
                <li>
                    <div className="back_block">
                    <FaGreaterThan onClick={onBackArrowClick} />
                    </div>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/events-training/events-training"}>
                      <span>CL:AIRE Training</span>
                    </NavLink>
                  </li>
                  <li>
                    {" "}
                    <NavLink to={"https://www.claire.co.uk/eLearning/"}>
                      <span>CL:AIRE eLearning</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/events-training/con-land-forums"}>
                      <span>Con Land Forums</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/events-training/cl-aire-20th-anniversary-conference"}>
                      <span>CL:AIRE 20th Anniversary Conference</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/events-training/cl-aire-events-calendar"}>
                      <span>CL:AIRE Events Calendar</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to={"https://www.claire.co.uk/projects-and-initiatives"}>
                  <span onClick={onLinkClickHandler}>
                    Projects & Initiatives
                  </span>
                  <span
                    onClick={(e) => {
                     onArrowClickHandler(e,4)
                    }}
                  >
                    <FaGreaterThan />{" "}
                  </span>
                </NavLink>
                {/* submenu */}
                <ul className={`mob_submenu mob_image_submenu one mob_hover_none ${isSubmenuActive[4] ? "mob_submenu_active" : "" }`}>
                <li>
                    <div className="back_block">
                    <FaGreaterThan onClick={onBackArrowClick} />
                    </div>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/dow-cop"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/templates/rt_salient/custom/images/logos/menu_logos/dowcop-menu-new.png"
                          alt="QP Register"
                        />
                        <p className="submenu_image_title">DoW:CoP</p>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/cl-aire-register-of-materials"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/templates/rt_salient/custom/images/logos/menu_logos/dowcop-menu-new.png"
                          alt="Register of Materials"
                        />
                        <p className="submenu_image_title">
                          Register of Materials
                        </p>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/qualified-person-register"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/templates/rt_salient/custom/images/logos/menu_logos/dowcop-menu-new.png"
                          alt="QP Register"
                        />
                        <p className="submenu_image_title">QP Register</p>
                      </div>
                    </NavLink>
                  </li>{" "}
                  <li>
                    <NavLink to={"https://www.claire.co.uk/commerce/112347-dow-cop"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/templates/rt_salient/custom/images/logos/menu_logos/dowcop-menu-new.png"
                          alt="DoWCoP Training"
                        />
                        <p className="submenu_image_title">DoWCoP Training</p>
                      </div>
                    </NavLink>
                  </li>{" "}
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/nqms"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/templates/rt_salient/custom/images/nqms-menu.png"
                          alt="NQMS"
                        />
                        <p className="submenu_image_title">NQMS</p>
                      </div>
                    </NavLink>
                  </li>{" "}
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/nqms-declaration"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/templates/rt_salient/custom/images/nqms-menu.png"
                          alt="NQMS Declaration"
                        />
                        <p className="submenu_image_title">NQMS Declaration</p>
                      </div>
                    </NavLink>
                  </li>{" "}
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/nqms-sqp-register"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/templates/rt_salient/custom/images/nqms-menu.png"
                          alt="NQMS SQP Register"
                        />
                        <p className="submenu_image_title">NQMS SQP Register</p>
                      </div>
                    </NavLink>
                  </li>{" "}
                  <li>
                    <NavLink  to={"https://www.claire.co.uk/projects-and-initiatives/local-authorities-map"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/templates/rt_salient/custom/images/nqms-menu.png"
                          alt="NQMS LA Map"
                        />
                        <p className="submenu_image_title">NQMS LA Map</p>
                      </div>
                    </NavLink>
                  </li>{" "}
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/gpvs"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/images/logos/menu_logos/gpv-logo-menu.png"
                          alt="GPVS"
                        />
                        <p className="submenu_image_title">GPVS</p>
                      </div>
                    </NavLink>
                  </li>{" "}
                  <li>
                    <NavLink to={"https://www.claire.co.uk/commerce/112385-gas-protection-verification"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/images/logos/menu_logos/gpv-logo-menu.png"
                          alt="GPVS Declaration"
                        />
                        <p className="submenu_image_title">GPVS Declaration</p>
                      </div>
                    </NavLink>
                  </li>{" "}
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/sgpv-register"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/images/logos/menu_logos/gpv-logo-menu.png"
                          alt="SGPV Register"
                        />
                        <p className="submenu_image_title">SGPV Register</p>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/tgpv-register"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/images/logos/menu_logos/gpv-logo-menu.png"
                          alt="TGPV Register"
                        />
                        <p className="submenu_image_title">TGPV Register</p>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/surf-uk"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/images/logos/menu_logos/surf-menu.png"
                          alt="SuRF UK"
                        />
                        <p className="submenu_image_title">SuRF UK</p>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/surf-international"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/images/logos/menu_logos/surf-intl-menu.png"
                          alt="SuRF International"
                        />
                        <p className="submenu_image_title">
                          SuRF International
                        </p>
                      </div>
                    </NavLink>
                  </li>{" "}
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/isra-surf-int-l"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/images/logos/menu_logos/isra-menu.png"
                          alt="ISRA"
                        />
                        <p className="submenu_image_title">ISRA</p>
                      </div>
                    </NavLink>
                  </li>{" "}
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/category-4-screening-levels"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/images/logos/menu_logos/c4sl-menu.png"
                          alt="C4SL"
                        />
                        <p className="submenu_image_title">C4SL</p>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/asbestos-in-soil"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/images/logos/menu_logos/jiwg-menu.png"
                          alt="Asbestos in Soil"
                        />
                        <p className="submenu_image_title">Asbestos in Soil</p>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/asbestos-training"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/images/logos/menu_logos/asbestos_training.png"
                          alt="Asbestos Training"
                        />
                        <p className="submenu_image_title">Asbestos Training</p>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/land-forum"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/images/logos/menu_logos/national-brownfield-forum-menu.png"
                          alt="Brownfield Forum"
                        />
                        <p className="submenu_image_title">Brownfield Forum</p>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/projects-and-initiatives/gwsdat"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/images/logos/menu_logos/gwsdat2-menu.png"
                          alt="GWSDAT"
                        />
                        <p className="submenu_image_title">GWSDAT</p>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to={"https://www.claire.co.uk/information-centre"}>
                  <span onClick={onLinkClickHandler}>Knowledge Centre</span>
                  <span
                    onClick={(e) => {
                     onArrowClickHandler(e,5)
                    }}
                  >
                    <FaGreaterThan />{" "}
                  </span>
                </NavLink>
                {/* submenu */}
                <ul className={`mob_submenu mob_image_submenu two mob_hover_none ${isSubmenuActive[5] ? "mob_submenu_active" : "" }`}>
                  <li>
                    <div className="back_block">
                    <FaGreaterThan  onClick={onBackArrowClick}/>
                    </div>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/information-centre/water-and-land-library-wall"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/templates/rt_salient/custom/images/logos/menu_logos/wall-menu.png"
                          alt="Water &amp; Land Library"
                        />
                        <p className="submenu_image_title">
                          Water &amp; Land Library
                        </p>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/information-centre/cl-aire-publications"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/images/logos/menu_logos/menu-knowledge-centre.png"
                          alt="CL:AIRE Library"
                        />
                        <p className="submenu_image_title">CL:AIRE Library</p>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/information-centre/video-and-podcast-library"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/templates/rt_salient/custom/images/logos/menu_logos/video-menu.png"
                          alt="Videos &amp; Podcasts"
                        />
                        <p className="submenu_image_title">
                          Videos &amp; Podcasts
                        </p>
                      </div>
                    </NavLink>
                  </li>{" "}
                  <li>
                    <NavLink to={"https://www.claire.co.uk/information-centre/research-projects"}>
                      <div>
                        <img
                          src="https://www.claire.co.uk/templates/rt_salient/custom/images/logos/menu_logos/research-menu2.png"
                          alt="Research Projects"
                        />
                        <p className="submenu_image_title">Research Projects</p>
                      </div>
                    </NavLink>
                  </li>{" "}
                </ul>
              </li>
              <li>
                <NavLink to={"https://www.claire.co.uk/commerce"}>
                  <span onClick={onLinkClickHandler}>Book or buy</span>{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to={"https://www.claire.co.uk/help-desk"}>
                  <span onClick={onLinkClickHandler}>Help Desk</span>
                  <span
                    onClick={(e) => {
                     onArrowClickHandler(e,6)
                    }}
                  >
                    <FaGreaterThan />{" "}
                  </span>
                </NavLink>
                {/* submenu */}
                <ul className={`mob_submenu ${isSubmenuActive[6] ? "mob_submenu_active" : "" }`}>
                <li>
                    <div className="back_block">
                    <FaGreaterThan onClick={onBackArrowClick} />
                    </div>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/help-desk/view-my-help-requests"}>
                      <span>View My Help Requests</span>{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"https://www.claire.co.uk/help-desk/how-to-articles"}>
                      <span>How to articles</span>{" "}
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};

export default MobSideBar;
