import React, { memo, useCallback, useState, useEffect, useRef } from "react";
import { GoogleMap, InfoWindowF, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { PurpleTick, BlueTick, Greentick } from "../../assets/images/index";
import "./style.scss";

function Map(props) {
  const { data: mapsDatas, sites, onFilter, onClear, activeMap } = props;
  const center = { lat: 53.689659, lng: -2.522821};
  
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });
  const [map, setMap] = useState(null);
  const [popupInfo, onMarker] = useState(false);

  // const icons = useRef({});
  const [icons,setIcons]= useState({});

  useEffect(() => {
   if (window.google && window.google.maps && Object.keys(icons).length === 0 ) {
    setIcons({
      donor: {
        url: BlueTick,
        scaledSize: new window.google.maps.Size(20, 20),
      },
      receiver: {
        url: Greentick,
        scaledSize: new window.google.maps.Size(30, 30),
      },
      soil_treatment: {
        url: PurpleTick,
        scaledSize: new window.google.maps.Size(30, 30),
      },
    })
      // icons.current = {
      //   donor: {
      //     url: BlueTick,
      //     scaledSize: new window.google.maps.Size(20, 20),
      //   },
      //   receiver: {
      //     url: Greentick,
      //     scaledSize: new window.google.maps.Size(30, 30),
      //   },
      //   soil_treatment: {
      //     url: PurpleTick,
      //     scaledSize: new window.google.maps.Size(30, 30),
      //   },
      // };
    }
  }, [center ]);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, [isLoaded ]);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onMarkerClick = (evt) => {
    // console.log(evt);
  };
  return isLoaded ? (
    <div
    // className='map_container'
    >
      <div className="clearWrapper">
        <div className="wrapBlk">

          {sites.map((item, key) => {
            return (
              <div key={key}>
                <div className="textBlk">
                  {item.key === "donor" && (
                    <svg
                      width="24"
                      height="36"
                      viewBox="0 0 24 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_161_272)">
                        <path
                          d="M12 0C5.37254 0 0 5.42553 0 12.1184C0 18.8112 12 36 12 36C12 36 24 18.8112 24 12.1184C24 5.42553 18.6275 0 12 0ZM12 18.6812C10.7147 18.6812 9.45819 18.2963 8.38947 17.5752C7.32075 16.854 6.48779 15.8291 5.99591 14.6299C5.50403 13.4306 5.37533 12.1111 5.62609 10.838C5.87685 9.56492 6.4958 8.39553 7.40467 7.4777C8.31354 6.55986 9.47151 5.93481 10.7322 5.68158C11.9928 5.42835 13.2995 5.55832 14.487 6.05504C15.6745 6.55177 16.6894 7.39295 17.4035 8.47221C18.1176 9.55147 18.4988 10.8203 18.4988 12.1184C18.4988 13.8589 17.8141 15.5282 16.5953 16.759C15.3766 17.9898 13.7236 18.6812 12 18.6812Z"
                          fill="#37A6D7"
                        />
                        <path
                          d="M12.0007 18.5906C15.5899 18.5906 18.4995 15.6523 18.4995 12.0277C18.4995 8.40314 15.5899 5.46484 12.0007 5.46484C8.41156 5.46484 5.50195 8.40314 5.50195 12.0277C5.50195 15.6523 8.41156 18.5906 12.0007 18.5906Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_161_272">
                          <rect width="24" height="36" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  {item.key === "receiver" && (
                    <svg
                      width="24"
                      height="36"
                      viewBox="0 0 24 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_161_277)">
                        <path
                          d="M12 0C5.37254 0 0 5.42553 0 12.1184C0 18.8112 12 36 12 36C12 36 24 18.8112 24 12.1184C24 5.42553 18.6275 0 12 0ZM12 18.6812C10.7147 18.6812 9.45819 18.2963 8.38947 17.5752C7.32075 16.854 6.48779 15.8291 5.99591 14.6299C5.50403 13.4306 5.37533 12.1111 5.62609 10.838C5.87685 9.56492 6.4958 8.39553 7.40467 7.4777C8.31354 6.55986 9.47151 5.93481 10.7322 5.68158C11.9928 5.42835 13.2995 5.55832 14.487 6.05504C15.6745 6.55177 16.6894 7.39295 17.4035 8.47221C18.1176 9.55147 18.4988 10.8203 18.4988 12.1184C18.4988 13.8589 17.8141 15.5282 16.5953 16.759C15.3766 17.9898 13.7236 18.6812 12 18.6812Z"
                          fill="#71AF26"
                        />
                        <path
                          d="M12.0007 18.5906C15.5899 18.5906 18.4995 15.6523 18.4995 12.0277C18.4995 8.40314 15.5899 5.46484 12.0007 5.46484C8.41156 5.46484 5.50195 8.40314 5.50195 12.0277C5.50195 15.6523 8.41156 18.5906 12.0007 18.5906Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_161_277">
                          <rect width="24" height="36" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  {item.key === "soil_treatment" && (
                    <svg
                      width="24"
                      height="36"
                      viewBox="0 0 24 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_161_282)">
                        <path
                          d="M12 0C5.37254 0 0 5.42553 0 12.1184C0 18.8112 12 36 12 36C12 36 24 18.8112 24 12.1184C24 5.42553 18.6275 0 12 0ZM12 18.6812C10.7147 18.6812 9.45819 18.2963 8.38947 17.5752C7.32075 16.854 6.48779 15.8291 5.99591 14.6299C5.50403 13.4306 5.37533 12.1111 5.62609 10.838C5.87685 9.56492 6.4958 8.39553 7.40467 7.4777C8.31354 6.55986 9.47151 5.93481 10.7322 5.68158C11.9928 5.42835 13.2995 5.55832 14.487 6.05504C15.6745 6.55177 16.6894 7.39295 17.4035 8.47221C18.1176 9.55147 18.4988 10.8203 18.4988 12.1184C18.4988 13.8589 17.8141 15.5282 16.5953 16.759C15.3766 17.9898 13.7236 18.6812 12 18.6812Z"
                          fill="#583768"
                        />
                        <path
                          d="M12.0007 18.5906C15.5899 18.5906 18.4995 15.6523 18.4995 12.0277C18.4995 8.40314 15.5899 5.46484 12.0007 5.46484C8.41156 5.46484 5.50195 8.40314 5.50195 12.0277C5.50195 15.6523 8.41156 18.5906 12.0007 18.5906Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_161_282">
                          <rect width="24" height="36" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  {/* <span
                 
                  style={{ backgroundColor: item.color }}
                ></span> */}
                  <div
                    onClick={() => onFilter(item)}
                    className={
                      activeMap.value === item.value ? "activeLabel" : ""
                    }
                  >
                    {item.value}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div onClick={() => onClear()} className="clearLink">Clear Selection</div>
      </div>
         <div className="map_sec_content_wrapper">
         <GoogleMap
        mapContainerClassName="map_container"
        center={center}
        zoom={6}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {
        // Object.keys(icons.current).length > 0 
          Object.keys(icons).length > 0   &&
          mapsDatas.map((el, i) => {
            let lat = parseFloat(el.position !== null && el?.position[0], 10);
            let lng = parseFloat(el.position !== null && el?.position[1], 10);
            // let iconUrl = icons.current[el.type].url;
            let iconUrl = icons[el.type]?.url;
            return (
              <MarkerF
                id={iconUrl}
                key={i}
                onClick={() => { onMarker(el) }}
                icon={{
                  url: iconUrl,
                  // scaledSize: new window.google.maps.Size(25, 25)
                }}
                animation="DROP"
                position={{ lat: lat, lng: lng }}
              >
                {popupInfo === el &&
                  <InfoWindowF
                    onCloseClick={() => onMarker({})}
                  >
                    <div className="leafletPopup">
                      <div className="headRef">{popupInfo.ref}</div>
                      <div className="addressHead">{popupInfo.address}</div>
                    </div>
                  </InfoWindowF>}
              </MarkerF>
            );
          })}
      </GoogleMap>
         <div className="map_content">
	  <p className="home_title_sub_content">The full Register is available in the table below and the site locations are pinned on the map. Navigating the map may help you to identify sites of interest. The reference numbers on the pins correspond to the entries on the Register.</p>
        <p className="home_title_sub_content">Please check the record in the appropriate Donor, Receiver or Soil Treatment Facility list for further site details and to enquire about the availability of materials.</p>
         </div>
          </div>
    </div>
  ) : (
    <></>
  );
}

export default memo(Map);
