import "./style.scss";
import React, { useEffect, useState } from "react";

const Dropdown = ({ options,setItemsPerPage,ispagination }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  useEffect(()=>{
    if(ispagination){
      setItemsPerPage(  selectedOption ? selectedOption.label : options[1].label)
    }
  },[selectedOption])
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div
      className="dropdown_container"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="dropdown_header">
        {selectedOption ? selectedOption.label : ispagination ? options[1].label :options[0].label}
        <span style={isOpen ? { transform: 'rotate(180deg)' } : {}}>
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L7 7L13 1"
              stroke="#3B3F4C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      {
        <ul className={`${ isOpen ? "dropdown_options open" : "dropdown_options"}`}>
          {options.map((option) => (
            <li key={option.value} onClick={() => handleOptionSelect(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      }
    </div>
  );
};

export default Dropdown;
